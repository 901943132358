import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { ForgotPasswordParams, UpdateCognitoContext } from './types';

const forgotPassword = (updateCognitoContext: UpdateCognitoContext, userPool: CognitoUserPool) => ({ username, onSuccess, onFailure }: ForgotPasswordParams) => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  user.forgotPassword({
    onSuccess() {
      updateCognitoContext({ user });
      onSuccess();
    },

    // no message if user does not have an account yet
    onFailure(err) {
      onFailure(err.message);
    },
  });
};

export default forgotPassword;
