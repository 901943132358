import { useInfiniteQuery } from '@tanstack/react-query';

import { FormValues as GamesFormValues } from '../components/GamesForm';
import { GetGamesResult } from '../types/GetGamesResult';
import { fetchGames } from '../services/fetchGames';

const LIMIT = 9;


const getNextPageParam = (lastPage: GetGamesResult) =>
  lastPage.offset + lastPage.results.length < lastPage.total ? lastPage.offset + LIMIT : undefined;

export const useGamesQuery = (formValues: GamesFormValues) =>
  useInfiniteQuery(
    ['games', { formValues }],
    async ({ pageParam: offset = 0 }) => {
      const { data } = await fetchGames({ offset, limit: LIMIT, ...formValues });
      return data;
    },
    {
      getNextPageParam,
    },
  );