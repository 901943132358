import { Cam, Cams, Store, StoreHookReturn } from '@/types/camspec';
import { PtzMessage } from '../types';
import useServerStateContext from './useServerStateContext';
import useWebSocket from './useWebSocket';


// using a module-scoped store because we need to track this in such a way that
// is shareable, but does not trigger state updates.
let store: Store = {};
const cams: Cams = {};

export const useTiltStore = (): StoreHookReturn => {

  const handleChange = (cameraId: string, value: number) => {
    store = {
      ...store,
      [cameraId]: value,
    };
  };

  const socket = useWebSocket<PtzMessage>('/ptz/tilt', {
    onMessage: (message) => {
      handleChange(message.cameraId, message.value);
    },
  });

  const reset = () => {
    Object.keys(cams).forEach((cameraId) => {
      const { value:tilt } = cams[cameraId];
      if (!Number.isFinite(tilt)) return;
      socket.send({ cameraId, value: tilt });
      handleChange(cameraId, tilt);
    })
  }

  const init = (cam: Cam) => {
    const { cameraId } = cam;
    const { tilt } = cam;

    if (!Number.isFinite(tilt)) return;

    cams[cameraId] = { value:tilt };

    socket.send({ cameraId, value: tilt });
    handleChange(cameraId, tilt);
  };

  const useServerStateContextHook = useServerStateContext();
  if (!useServerStateContextHook) return { init, reset };
  const { getServerStateAndFunctions } = useServerStateContextHook;


  const setValue = (value: number) => {
    if (!Number.isFinite(value)) {
      return;
    }

    const cameraId = getServerStateAndFunctions().videoSourceCameraId;
    socket.send({ cameraId, value });
    handleChange(cameraId, value);
  };

  const getValue = () => {
    const cameraId = getServerStateAndFunctions().videoSourceCameraId;
    return store[cameraId];
  };

  return { getValue, setValue, init, reset };
};
