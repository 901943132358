import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import SimpleBar from 'simplebar-react';
import { grey } from '@mui/material/colors';

import { KeyboardShortcuts } from '@/shared/constants/keyboardShortcuts';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import ordinalNoun from '@/shared/services/ordinalNoun';

import useAllClipTagsQuery from '../../clipTags/hooks/useAllClipTagsQuery';
import { PANEL_PADDING } from '../../games/constants';
import useCurrentGameClipsQuery, { getQueryKey } from '../hooks/useCurrentGameClipsQuery';
import getClipsFilter from '../services/getClipsFilter';
import {  ClipType } from '../types';
import ClipRecord from './ClipRecord';
import useGameTeamsQuery from '@/features/games/hooks/useGameTeamsQuery';
import DownloadClipDialog from '@/shared/components/DownloadClipDialog';

const ClipsAndBookmarksPanel = () => {
  const clipTagsQuery = useAllClipTagsQuery();
  const {
    state: { isEditing, filter, tagsFilter },
    setFilter,
    setTagsFilter,
    handleAddClip,
    handleAddBookmark,
  } = useClipStateContext();

  const queryClient = useQueryClient();
  const gameId = useCurrentGameId();
  const teams = useGameTeamsQuery();
  const clipsQuery = useCurrentGameClipsQuery();
  const [clipId, setClipId] = React.useState<number | undefined>(undefined);
  const [downloadClipDialogOpen, setDownloadClipDialogOpen] = React.useState(false);
  const [filteredClips, setFilteredClips] = React.useState<ClipType[]>([]);

  const viewingToolExpandablePanel = React.useRef(null);

  const simpleBar = React.useRef(null);


  React.useEffect(() => {
    if (clipsQuery.data) {
      setFilteredClips(clipsQuery.data.filter(getClipsFilter(filter, tagsFilter)));
    }
  }, [clipsQuery.dataUpdatedAt]);

  React.useEffect(() => {
    if (clipsQuery.data) {
      clipsQuery.refetch();
    }
  }, [tagsFilter, filter]);


  const [addClipButtonIsLoading, setAddClipButtonIsLoading] = React.useState(false);

  // do not put any react hook under this line ...
  if (!clipsQuery.data) return <CircularProgress m={2} />;

  const handleDownloadClip = (id: number) => {
    setClipId(id);
    setDownloadClipDialogOpen(true);
  };

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <DownloadClipDialog
        open={downloadClipDialogOpen}
        clipId={clipId}
        onClose={() => setDownloadClipDialogOpen(false)}
      />
      {!isEditing && (
        <Stack>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            ml={PANEL_PADDING}
            mr={PANEL_PADDING - 1}
          >
            <Stack direction="row" spacing={1}>
              <LoadingButton
                startIcon={<AddIcon />}
                onClick={handleAddBookmark}
                disabled={isEditing}
                variant="outlined"
              >
                Bookmark ({KeyboardShortcuts.AddBookmark.keys.join('+')})
              </LoadingButton>
              <LoadingButton
                loading={addClipButtonIsLoading}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setAddClipButtonIsLoading(true);
                  handleAddClip();
                  setTimeout(() => setAddClipButtonIsLoading(false), 2000);
                }}
                disabled={isEditing}
              >
                Clip ({KeyboardShortcuts.AddClip.keys.join('+')})
              </LoadingButton>
            </Stack>
            <Tooltip title="Refresh Bookmarks & Clips" arrow>
              <IconButton
                aria-label="refresh"
                onClick={() => queryClient.invalidateQueries(getQueryKey(gameId))}
                sx={{ alignSelf: 'flex-end' }}
                disabled={isEditing}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {clipTagsQuery.data && clipTagsQuery.data.length > 0 && (
            <Autocomplete
              multiple
              sx={{ mx: PANEL_PADDING, mt: 2, mb: 2 }}
              options={clipTagsQuery.data.map((i) => ({ label: i.name, id: i.id }))}
              value={tagsFilter.label}
              size="small"
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by Tags" />
              )}
              onChange={(_, val) => setTagsFilter(val)}
              disabled={isEditing}
            />
          )}

          <Stack
            direction="row"
            spacing={1}
            my={2}
            mx={PANEL_PADDING}
            justifyContent="space-between"
            alignItems="flex-end"
            ref={viewingToolExpandablePanel}
          >
            <FormControl
              variant="outlined"
              sx={{ my: 1, flex: '1 1 0px' }}
              fullWidth
              disabled={isEditing}
            >
              <InputLabel id="clips-filter-select-label">Filter by</InputLabel>
              <Select
                labelId="clips-filter-select-label"
                id="clips-filter-select"
                label="Filter by type"
                value={filter}
                onChange={(e) => setFilter(e.target.value as ClipType | 'all')}
                variant="outlined"
                size="small"
                margin="none"
                fullWidth
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value={ClipType.Bookmark}>Bookmarks</MenuItem>
                <MenuItem value={ClipType.Clip}>Clips</MenuItem>
              </Select>
            </FormControl>

            <Typography
              variant="subtitle2"
              fontStyle="italic"
              textAlign="right"
              sx={{ opacity: 0.5, mx: PANEL_PADDING, flex: '1 1 0px' }}
            >
              Showing {ordinalNoun(filteredClips.length, 'Record', 'Records')}
            </Typography>
          </Stack>

          <Divider />
        </Stack>
      )}


      <SimpleBar style={{ color: 'white', height: '100%' }} ref={simpleBar}>
        {filteredClips.map((row, i) => (
          <ClipRecord
            key={row.id}
            clip={row}
            isFirst={i === 0}
            teams={teams}
            downloadCliphandler={handleDownloadClip}
          />
        ))}
      </SimpleBar>

    </Stack>
  );
};

export default ClipsAndBookmarksPanel;
