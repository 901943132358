import * as React from 'react';

import { Box, Divider, ListItemText, Stack, Typography } from '@mui/material';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';
import { useScoreboardState } from '@/shared/hooks/useScoreboard';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';
import useGameDetailsQuery from '../hooks/useGameDetailsQuery';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

const getOrdinalSuffix = (i: number) => {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

const getNBAPeriodName = (p: number) => {
  // hawkeye's mapping
  switch (p) {
    case 100:
      return 'Pregame';
    case 200:
      return 'Interval';
    case 101:
      return 'End of 1st Quarter';
    case 102:
      return 'Halftime';
    case 103:
      return 'End of 3rd Quarter';
    case 104:
      return 'Final';
    case 1:
      return '1st Quarter';
    case 2:
      return '2nd Quarter';
    case 3:
      return '3rd Quarter';
    case 4:
      return '4th Quarter';
    default:
      return `${getOrdinalSuffix(p - 4)} Overtime`;
  }
};

function period(periodNumber: number, league: number, periodName: string) {
  switch (league) {
    case 2:
      return getNBAPeriodName(periodNumber);
    case 1:
      return `${periodNumber}${getOrdinalSuffix(periodNumber)} ${periodName}`;
    default:
      return `${periodNumber}${getOrdinalSuffix(periodNumber)} ${periodName}`;
  }
}
type Props = {
  data: {
    awayTeam: {
      abbreviation: string;
      externalid: string;
      name: string;
      score: number;
    };
    homeTeam: {
      abbreviation: string;
      externalid: string;
      name: string;
      score: number;
    };
    periodOfPlay: number;
    periodTimeRemaining: string;
    periodTimeElapsed: string;
    periodOrdinal: number;
    gameClock: string;
  };
};

const ScoreboardData = ({ data }: Props) => {
  const [scoreboard] = useScoreboardState();
  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  const periodName = gameTypeMetadataQuery.data?.periodName || '';

  if (!scoreboard || scoreboard.periodOfPlay === 0)
    return (
      <Stack
        direction="row"
        spacing={3}
        sx={{
          flexGrow: 1,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      />
    );
  return (
    <Stack
      direction="row"
      spacing={3}
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem />}
      sx={{
        flexGrow: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      <Stack direction="row" spacing={3} alignItems="center" style={{ width: '450px' }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <img
            loading="lazy"
            width="40"
            // eslint-disable-next-line react-hooks/rules-of-hooks
            src={useTeamLogoPath(data.awayTeam)}
            alt={data.awayTeam.abbreviation}
          />
          <ListItemText primary={data.awayTeam.abbreviation} secondary={data.awayTeam.name} />
          <Typography
            variant="h4"
            fontWeight="bold"
            color={(theme) =>
              scoreboard.scoreHome <= scoreboard.scoreAway
                ? theme.palette.grey[50]
                : theme.palette.grey[500]
            }
          >
            {scoreboard.scoreAway}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle1" color={(theme) => theme.palette.grey[600]}>
            @
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography
            variant="h4"
            fontWeight="bold"
            color={(theme) =>
              scoreboard.scoreHome >= scoreboard.scoreAway
                ? theme.palette.grey[50]
                : theme.palette.grey[400]
            }
          >
            {scoreboard.scoreHome}
          </Typography>
          <ListItemText
            primary={data.homeTeam.abbreviation}
            secondary={data.homeTeam.name}
            sx={{ '&.MuiListItemText-root': { textAlign: 'right' } }}
          />
          <img
            loading="lazy"
            width="40"
            src={useTeamLogoPath(data.homeTeam)}
            alt={data.homeTeam.abbreviation}
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box sx={{ width: '100px' }}>
          <Typography variant="h4" fontWeight="bold">
            {scoreboard.gameClock}
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          textTransform="uppercase"
          color={(theme) => theme.palette.grey[400]}
        >
          {period(scoreboard.periodOfPlay, data?.leagueid, periodName)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ScoreboardData;
