import { useRef } from 'react';

import { ScoreboardStateModel } from '../../features/camera/types';
import { ScoreboardMessage } from '../types';
import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from './withStateFromRef';

export const useScoreboardRef: WebSocketRefHook<ScoreboardStateModel> = (options = {}) => {
  const ref = useRef<ScoreboardStateModel>();

  useWebSocket<ScoreboardMessage>('/scoreboard', {
    onMessage: (message) => {
      ref.current = message;
      if (options.onChange) {
        options.onChange(message);
      }
    },
  });

  return [ref];
};

export const useScoreboardState = withStateFromRef(useScoreboardRef);
