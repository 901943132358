import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';
import { startCase } from 'lodash';
import { Tag } from '@/features/clips/types';

export const queryKey = ['ClipTags'];
const useAllClipTagsQuery = () => useQuery(queryKey, async () => {
  let { data } = await get<Tag[]>('tags');
  data = data.map((tag) => ({
    ...tag,
    name: startCase(tag.name),
  }));
  return data;
});

export default useAllClipTagsQuery;
