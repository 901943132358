import { configureHttp as configure } from '@truefit/http-utils';

import { apiGatewayUrl, dopplerConfiguration, nodeEnv } from '../shared/constants';

const IS_PROD_OR_STAGING = ['prd', 'production', 'stg', 'staging'].includes(nodeEnv);

function startsWithArrayElement(url, arr) {
  if (url === undefined) return false;
  return arr.some(element => url.startsWith(element));
}

function endsWithArrayElement(url, arr) {
  if (url === undefined) return false;
  return arr.some(element => url.endsWith(element));
}

const useApiGateway = (url: string, method: string) => [
  { url: 'stream', method: 'get' },
  { url: 'tracking/entity', method: 'put' },
  { url: 'tracking/point', method: 'put' },
  { url: 'tracking/registration', method: 'get' },
  { url: 'cameras', method: 'get' },
  { url: 'cameras/view', method: 'put' },
].some((endpoint) => url.startsWith(endpoint.url) && endpoint.method === method);

const getAutoIsoAndMessageBusEndpoint = (path?: string): string => {
  const gatewayUrl = new URL(apiGatewayUrl);
  gatewayUrl.pathname = path ?? '/';
  return gatewayUrl.toString();
};

export const getBusinessApiEndPoint = (url?: string): string => {
  const gatewayUrl = new URL(apiGatewayUrl);
  gatewayUrl.pathname = IS_PROD_OR_STAGING ? '/business-api' : `/business-api-${dopplerConfiguration}`;

  if (startsWithArrayElement(url, ['config', 'antiforgery-token', 'surface', 'user-league']) || endsWithArrayElement(url, ['logo'])) {
    gatewayUrl.pathname = IS_PROD_OR_STAGING ? '/unrestricted' : `/unrestricted-${dopplerConfiguration}`;
  }
  return gatewayUrl.toString();
};

export type HttpConfiguration = {
  path?: string;
  jwt?: string;
  identityToken?: string;
  handler?: string
};

export function configureHttp(httpConfig: HttpConfiguration) {
  const {
    jwt, path, identityToken
  } = httpConfig;


  const baseHeaders = {};
  if (jwt) {
    // @ts-ignore
    baseHeaders.Authorization = `Bearer ${jwt}`;
  }
  if (identityToken) {
    // @ts-ignore
    baseHeaders.CognitoIdentityToken = identityToken;
  }

  configure({
    baseConfig: {
      baseURL: getBusinessApiEndPoint(),
    },
    baseHeaders,
    configureInstance: (instance) => {
      instance.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response?.status === 401) {
            window.location.replace('/sign-in');
          }

          return Promise.reject(error);
        },
      );

      instance.interceptors.request.use((config) => ({
        ...config,
        baseURL: useApiGateway(config.url, config.method)
          ? getAutoIsoAndMessageBusEndpoint(path)
          : getBusinessApiEndPoint(config.url),
      }));
    },
  });
}
