import * as React from 'react';
import { Card, Stack, CardContent } from '@mui/material';
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import ListItemText from '@mui/material/ListItemText';

export interface TrendDetailsProps {
  title: React.ReactNode;
  value: React.ReactNode;
  data?: number[];
  limit?: number;
  style: React.CSSProperties;
}

export default function TrendDetails({ title, value, data, limit, style }: TrendDetailsProps) {
  return (
    <Card style={style}>
      <CardContent>
        <Stack direction="column">
          <ListItemText primary={title} secondary={value} />
          {data?.length > 0 && (
            <Sparklines
              data={data}
              limit={limit}
              margin={5}
              height={50}
              preserveAspectRatio="xMidYMid meet"
            >
              <SparklinesLine color="#ffffff" style={{ strokeWidth: 1, stroke: '#ffffff' }} />
              <SparklinesReferenceLine type="max" />
            </Sparklines>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

TrendDetails.defaultProps = {
  limit: 20,
  data: [],
};
