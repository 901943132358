import { clamp, isFinite } from 'lodash';
import { Cam } from '@/types/camspec';

import { PtzMessage } from '../types';
import useServerStateContext from './useServerStateContext';
import useWebSocket from './useWebSocket';

export const ZOOM_MIN = 1;
export const ZOOM_MAX = 180;

type Store = Record<string, number>;
type Cams = Record<string, number[]>;

type StoreHookReturn = {
  getValue?: () => number,
  setValue?: (value: number) => unknown,
  init: (cam: Cam) => void,
  reset: () => void,
  getMinFov?: (cameraId) => number,
  getMinFovs: () => Cams
};

// using a module-scoped store because we need to track this in such a way that
// is shareable, but does not trigger state updates.
let store: Store = {};
const cams: Cams = {};

export const useZoomStore = (): StoreHookReturn => {

  const handleChange = (cameraId: string, value: number) => {
    store = {
      ...store,
      [cameraId]: value,
    };
  };

  const getMinFovs = (): Cams => cams

  const socket = useWebSocket<PtzMessage>('/ptz/zoom', {
    onMessage: (message) => {
      handleChange(message.cameraId, message.value);
    },
  });

  const getMinFov = (cameraId: number) => {
    const [initialFov] = cams[cameraId];
    return initialFov;
  }

  const reset = () => {

    Object.keys(cams).forEach((cameraId) => {
      const [initialFov] = cams[cameraId];
      socket.send({ cameraId, value: initialFov });
      handleChange(cameraId, initialFov);
    });
  }


  const init = (cam: Cam) => {
    const { cameraId } = cam;
    let { initialFov, fovMin, fovMax } = cam;

    if (![initialFov, fovMin, fovMax].every(Number.isFinite)) return;

    initialFov = initialFov ?? 100;
    fovMin = fovMin ?? ZOOM_MIN;
    fovMax = fovMax ?? ZOOM_MAX;

    cams[cameraId] = [initialFov, fovMin, fovMax];

    const clamped = clamp(initialFov, fovMin, fovMax);
    socket.send({ cameraId, value: clamped });
    handleChange(cameraId, clamped);
  };


  const useServerStateContextHook = useServerStateContext();
  if (!useServerStateContextHook) return { init, reset, getMinFov, getMinFovs };
  const { getServerStateAndFunctions } = useServerStateContextHook;

  const setValue = (value: number) => {
    if (!isFinite(value)) {
      return;
    }

    const cameraId = getServerStateAndFunctions().videoSourceCameraId;
    const [, zoomMin, zoomMax] = cams[cameraId];
    const clamped = clamp(value, zoomMin, zoomMax);
    socket.send({ cameraId, value: clamped });
    handleChange(cameraId, clamped);
  };

  const getValue = () => {
    const cameraId = getServerStateAndFunctions().videoSourceCameraId;
    return store[cameraId];
  };

  return { getValue, setValue, init, reset, getMinFov ,getMinFovs};
};
