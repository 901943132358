import { useMutation } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { put } from '@truefit/http-utils';
import useCurrentUser, { User } from './useCurrentUser';

const useUpdateUser = () => {
  const useCurrentUserQuery = useCurrentUser();
  return useMutation(
    async (user: User) => {
      const response = await put<User>('user', { tz: user.tz });
      return response.data;
    },
    {
      onSuccess: () => {
        useCurrentUserQuery.refetch();
      },
    },
  );
};

export default useUpdateUser;
