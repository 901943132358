/* eslint-disable react/jsx-no-duplicate-props */

import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FilledInputProps } from '@mui/material/FilledInput';
import { FormHelperTextProps as FormHelperTextPropsType } from '@mui/material/FormHelperText';
import { InputProps as StandardInputProps } from '@mui/material/Input';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { InputLabelProps as InputLabelPropsType } from '@mui/material/InputLabel';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';

type Props = {
  name: string;
  autoComplete?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextPropsType>;
  fullWidth?: boolean;
  helperText?: ReactNode;
  id?: string;
  InputLabelProps?: Partial<InputLabelPropsType>;
  InputProps?:
    | Partial<StandardInputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
  inputProps?: InputBaseComponentProps;
  label?: ReactNode;
  margin?: 'none' | 'dense' | 'normal';
  multiline?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: string | number;
  rowsMax?: string | number;
  size?: 'small' | 'medium';
  type?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const HookFormTextField = ({
  name,
  autoComplete,
  autoFocus,
  defaultValue,
  disabled,
  FormHelperTextProps,
  fullWidth = true,
  helperText,
  id,
  InputLabelProps,
  InputProps,
  inputProps,
  label,
  margin = 'normal',
  multiline,
  placeholder,
  required,
  rows,
  rowsMax,
  size,
  type,
  variant = 'outlined',
  onBlur,
  onChange,
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const error = errors[name];
  const displayError = Boolean(error?.message);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onBlur: formOnBlur, onChange: formOnChange, value } }) => (
        <TextField
          name={name}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          error={displayError}
          FormHelperTextProps={FormHelperTextProps}
          fullWidth={fullWidth}
          helperText={displayError ? error.message : helperText}
          id={id ?? name}
          InputLabelProps={InputLabelProps}
          InputProps={InputProps}
          inputProps={inputProps}
          label={label}
          margin={margin}
          multiline={multiline}
          onBlur={(e) => {
            // Custom action here.
            if (onBlur) onBlur(e);

            // Then call the Controller's onBlur method.
            formOnBlur(e);
          }}
          onChange={(e) => {
            // Custom action here.
            if (onChange) onChange(e);

            // Then call the Controller's onBlur method.
            formOnChange(e);
          }}
          placeholder={placeholder}
          required={required}
          rows={rows}
          maxRows={rowsMax}
          size={size}
          type={type}
          value={value ?? ''}
          variant={variant}
        />
      )}
    />
  );
};

HookFormTextField.defaultProps = {
  autoComplete: 'off',
  autoFocus: false,
  disabled: false,
  fullWidth: true,
  margin: 'normal',
  multiline: false,
  required: false,
  size: 'medium',
  type: 'text',
  variant: 'outlined',
  rowsMax: 4,
  defaultValue: '',
  FormHelperTextProps: {},
  InputLabelProps: {},
  InputProps: {},
  inputProps: {},
  rows: 1,
  placeholder: '',
  helperText: '',
  id: undefined,
  label: '',
  onBlur: () => {},
  onChange: () => {},
};

export default HookFormTextField;
