/* eslint-disable import/prefer-default-export */
import { useMutation } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

const useEnqueueClipForExportMutation = () => useMutation(
  async (clipId: number) => {
    await get(`/clips/${clipId}/export`);
  },
  {
    cacheTime: 0,
  },
);

export default useEnqueueClipForExportMutation;
