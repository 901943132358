import { useRef, useState, useEffect } from 'react';
import { AutoIsoPositionModel } from '../../features/autoIso/types';
import { TrackingStateMessage } from '../types';
import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from './withStateFromRef';
import useGameTypeMetadataQuery from './useGameTypeMetadataQuery';

export const useTrackingStateRef: WebSocketRefHook<AutoIsoPositionModel[]> = (options = {}) => {
  const ref = useRef<AutoIsoPositionModel[]>([]);
  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  const [surfaceWidth, setSurfaceWidth] = useState(0);
  const [surfaceHeight, setSurfaceHeight] = useState(0);

  useEffect(() => {
    if (gameTypeMetadataQuery.data?.surfaceWidth && gameTypeMetadataQuery.data?.surfaceHeight) {
      setSurfaceWidth(gameTypeMetadataQuery.data?.surfaceWidth);
      setSurfaceHeight(gameTypeMetadataQuery.data?.surfaceHeight);
    }
  }, [gameTypeMetadataQuery.data]);

  const isActive = (e) => e.x >= -(surfaceWidth / 2)
    && e.x <= surfaceWidth / 2
    && e.y >= -(surfaceHeight / 2)
    && e.y <= surfaceHeight / 2;

  const handleChange = (value: AutoIsoPositionModel[]) => {
    ref.current = value;

    if (options.onChange) {
      options.onChange(value);
    }
  };

  useWebSocket<TrackingStateMessage>('/tracking-state', {
    onMessage: (message) => {
      const active = message.entities.filter(isActive);
      handleChange(active);
    },
  });

  return [ref];
};

export const useTrackingStateState = withStateFromRef(useTrackingStateRef);
