import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { GetSessionParams, UpdateCognitoContext } from './types';
import getUserAttributes from './getUserAttributes';
import setHttpAuth from './setHttpAuth';
import setUpRefreshToken from './setupRefreshToken';

// eslint-disable-next-line max-len
const getSession = (updateCognitoContext: UpdateCognitoContext, userPool: CognitoUserPool) => ({ onSuccess, onFailure }: GetSessionParams) => {
  const user = userPool?.getCurrentUser();

  if (user == null || typeof user === 'undefined') {
    onFailure('Unable to load local session');
    return;
  }

  user.getSession((err: Error | null, userSession: CognitoUserSession) => {
    if (err) {
      onFailure(err.message);
      return;
    }

    if (!userSession.isValid()) {
      onFailure('Cached session is invalid');
      return;
    }

    getUserAttributes({
      user,
      onFailure,
      onSuccess(userAttributes) {
        updateCognitoContext({
          userAttributes,
          user,
          userSession,
          authenticated: true,
          newPasswordRequired: false,
        });

        setHttpAuth(userSession);

        setUpRefreshToken({
          updateCognitoContext,
          user,
          userSession,
        });

        onSuccess(userSession);
      },
    });
  });
};

export default getSession;
