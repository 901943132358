import React, { ReactNode, useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import { LeagueContext } from './LeagueContext';

import CXVideoHub from '@/assets/cx_video_hub.png';
import { AnimatedCosmLogo } from '@/shared/components';

type Props = {
  children: ReactNode;
};

export default ({ children }: Props) => {
  const { leagueLogo } = useContext(LeagueContext);

  const autoIsoLogoControls = useAnimation();
  const cxVideoHubLogoControls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      await cxVideoHubLogoControls.start({
        opacity: 1,
        transition: { duration: 1 },
      });
      await autoIsoLogoControls.start({
        opacity: 1,
        transition: { duration: 1 },
      });
    };
    setTimeout(async () => {
      sequence();
    }, 250);
  }, []);

  return (
    <Container maxWidth="sm" fixed>
      <Box
        sx={{
          height: '100vh',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          sx={{
            width: '100%',
            backgroundColor: '#212121',
            borderRadius: 2,
            shadow: 24,
            px: 3,
            pt: 2,
            pb: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
              flexDirection: 'column',
            }}
          >
            <AnimatedCosmLogo leagueLogo={leagueLogo} height={40} />
            <motion.div initial={{ opacity: 0 }} animate={cxVideoHubLogoControls}>
              <img src={CXVideoHub} alt="CXVideo Hub" style={{ height: 15, marginTop: 15 }} />
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={autoIsoLogoControls}>
              <Typography variant="subtitle2" sx={{ mb: 3, mt: 1 }}>
                with AUTO-ISO&trade;
              </Typography>
            </motion.div>

            {children}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};
