import React, { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import VideocamTwoToneIcon from '@mui/icons-material/VideocamTwoTone';
import { Box, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { useTrackingStateState } from '@/shared/hooks/useTrackingState';

import { getBusinessApiEndPoint } from '../../../util';
import useAutoIsoEntitiesQuery from '../hooks/useAutoIsoEntitiesQuery';
import { AutoIsoModel, AutoIsoObjectType } from '../types';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';

type Props = {
  elementSize: { width: number; height: number };
};

const cameraSize = 16;

const useStyles = makeStyles(() => ({
  obj: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'black',
  },
}));

const GamePositionsRepresentation = ({ elementSize }: Props) => {
  const businessApiUrl = getBusinessApiEndPoint('surface');
  const gameId = useCurrentGameId();
  const theme = useTheme();
  const {
    cameraSettingsState,
    videoSourceCameraId,
    singleCurrentlyTrackedObjectId,
    setVideoSourceCameraId,
    setTrackingEntityId,
  } = useServerStateContext();
  const { showCameraIcons } = useFlags();
  const autoIsoQuery = useAutoIsoEntitiesQuery();
  const { lookup: autoIsoEntityLookup, homeTeam } = autoIsoQuery.data;
  const [autoIsoPositionsState] = useTrackingStateState();
  const styles = useStyles();
  const [top, setTop] = useState<string>();

  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  const [surfaceWidth, setSurfaceWidth] = useState(0);
  const [surfaceHeight, setSurfaceHeight] = useState(0);
  const [surfaceName, setSurfaceName] = useState('Surface');
  const [objectOfPlay, setObjectOfPlay] = useState('Ball');

  useEffect(() => {
    if (gameTypeMetadataQuery.data?.surfaceWidth && gameTypeMetadataQuery.data?.surfaceHeight) {
      setSurfaceWidth(gameTypeMetadataQuery.data?.surfaceWidth);
      setSurfaceHeight(gameTypeMetadataQuery.data?.surfaceHeight);
      setSurfaceName(gameTypeMetadataQuery.data?.surfaceName);
      setObjectOfPlay(gameTypeMetadataQuery.data?.objectOfPlay);
    }
  }, [gameTypeMetadataQuery.data]);

  const getLabel = (entity: AutoIsoModel) => {
    if (entity.type === AutoIsoObjectType.Player || entity.type === AutoIsoObjectType.Official) {
      return entity.playerNumber;
    }

    return objectOfPlay?.charAt(0).toUpperCase();
  };

  const getObjectSize = (entity: AutoIsoModel) => {
    if (entity.type === AutoIsoObjectType.Player || entity.type === AutoIsoObjectType.Official) {
      return 24;
    }
    return 15;
  };

  const getObjectColor = (entity: AutoIsoModel) => {
    if (entity.id === singleCurrentlyTrackedObjectId) {
      return 'white';
    }

    if (entity.type === AutoIsoObjectType.Player) {
      const onHomeTeam =
        entity.type === AutoIsoObjectType.Player && entity.playerTeamId === homeTeam.id;
      return onHomeTeam ? theme.palette.error.light : theme.palette.info.light;
    }
    if (entity.type === AutoIsoObjectType.Official) {
      return theme.palette.grey[700];
    }

    return theme.palette.primary.main;
  };

  const getObjectBorder = (entity: AutoIsoModel) => {
    if (entity.id === singleCurrentlyTrackedObjectId) {
      return '1px solid black';
    }
    return '1px solid black';
  };

  return (
    <Box
      sx={{
        touchAction: 'none',
        userSelect: 'none',
        width: elementSize.width,
        height: elementSize.height,
        position: 'relative',
      }}
    >
      <img
        alt={surfaceName}
        src={`${businessApiUrl}/games/${gameId}/surface`}
        style={{ width: '100%', position: 'absolute' }}
      />
      
      {!showCameraIcons && (
        <>
          {Object.values(cameraSettingsState)
            .filter((c) => Number.isFinite(c.x) && Number.isFinite(c.y) && Number.isFinite(c.z))
            .map((camera) => {
              let xPercent = (camera.x / (surfaceWidth / 2) + 1) / 2.0;
              let yPercent = (camera.y / (surfaceHeight / 2) + 1) / 2.0;

              if (xPercent < 0) xPercent = 0;
              if (yPercent < 0) yPercent = 0;

              const xVal = (elementSize.width - cameraSize) * xPercent;
              const yVal = (elementSize.height - cameraSize) * yPercent;

              if (
                !Number.isFinite(xVal) ||
                !Number.isFinite(yVal) ||
                Number.isNaN(xVal) ||
                Number.isNaN(yVal)
              )
                {
                  return null;
                }
              if (!camera?.id) return null;
              if (!showCameraIcons) return null;
              return (
                <Tooltip key={`${camera.id}-camera`} title={camera.name} arrow>
                  <VideocamTwoToneIcon
                    onClick={() => {
                      setVideoSourceCameraId(camera, `Virtual ${surfaceName}`);
                    }}
                    style={{
                      position: 'absolute',
                      left: xVal || 0,
                      bottom: yVal || 0,
                      transform: `rotate(${360 - camera.angle}deg)`,
                      width: cameraSize,
                      height: cameraSize,
                      cursor: 'pointer',
                      color:
                        videoSourceCameraId === camera.id ? theme.palette.primary.main : '#888888',
                    }}
                  />
                </Tooltip>
              );
            })}
        </>
      )}

      <>
        {autoIsoPositionsState.map((pos) => {
          const entity = autoIsoEntityLookup[pos.id];
          if (!entity) return null;
          if(pos.x===0 && pos.y ===26 && pos.z ===1) return null;
          if(pos.z<0) return null;
          const xPercent = (pos.x / (surfaceWidth / 2) + 1) / 2.0;
          const yPercent = (pos.y / (surfaceHeight / 2) + 1) / 2.0;
          const objectSize = getObjectSize(entity);
          const objectLabelFontSize = objectSize / 2;
          const xVal = (elementSize.width - objectSize) * xPercent;
          const yVal = (elementSize.height - objectSize) * yPercent;
          const color = getObjectColor(entity);
          const isTracked = entity.id === singleCurrentlyTrackedObjectId;
          return (
            <div
              className={styles.obj}
              style={{
                cursor: 'pointer',
                width: objectSize,
                height: objectSize,
                borderRadius: objectSize / 2,
                backgroundColor: color,
                left: xVal,
                bottom: yVal,
                fontSize: objectLabelFontSize,
                color: 'black',
                border: getObjectBorder(entity),
                zIndex: isTracked || top === entity.id ? 2 : 1,
                boxShadow: '0px 0px 5px 5px #00000022',
                opacity: isTracked || top === entity.id ? 1 : 0.6,
              }}
              onClick={() => setTrackingEntityId(entity, `Virtual ${surfaceName}`)}
              onMouseOver={() => setTop(entity.id)}
              onFocus={() => {}}
              aria-hidden="true"
              key={pos.id}
            >
              {getLabel(entity)}
            </div>
          );
        })}
      </>
    </Box>
  );
};

export default GamePositionsRepresentation;
