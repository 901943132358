import { DurationWithSubseconds } from '../types';

const durationToMilliseconds = (duration: Duration): number => (
  (duration.years || 0) * 365.25 * 24 * 60 * 60 * 1000
    + (duration.weeks || 0) * 7 * 24 * 60 * 60 * 1000
    + (duration.days || 0) * 24 * 60 * 60 * 1000
    + (duration.hours || 0) * 60 * 60 * 1000
    + (duration.minutes || 0) * 60 * 1000
    + (duration.seconds || 0) * 1000
);

export const durationWithSubsecondsToMilliseconds = (duration: DurationWithSubseconds): number => durationToMilliseconds(duration) + duration.subSecondPercent * 1000;

export default durationToMilliseconds;
