import React from "react";


interface RotatingIconProps {
    angle: number;
    size: number;
}

const RotatingIcon: React.FC<RotatingIconProps> = ({ angle, size }) => {
  const iconStyle = {
    fill: "#fff",
    transform: `rotate(${angle}deg)`,
    transition: "transform 0.3s ease-in-out", // Optional: Add a smooth transition effect
    filter: 'drop-shadow(0px 0px 2px rgb(0 0 0 / 0.9))'
  };

  return (
    <div>
      <svg
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        width={size}
        version="1.1"
        id="svg2"
        style={iconStyle}
      >
        <path
          d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
          id="path1"
        />
        <path d="M12 5.166V12h6.834A6.817 6.817 0 0 0 12 5.166z" id="path2" />
      </svg>
    </div>
  );
};

export default RotatingIcon;
