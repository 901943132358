import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import { CameraSettingsModel, CameraSettingsState, CameraStateModel } from '../types';

export const getQueryKey = (gameId: number) => ['Games', gameId, 'Cameras'];

const useCamerasQuery = () => {
  const gameId = useCurrentGameId();

  return useQuery(
    getQueryKey(gameId),
    async (): Promise<CameraSettingsState> => {
      const response = await get<CameraStateModel>(`cameras`);
      return response.data.cameraSettings.reduce<CameraSettingsState>(
        (state: CameraSettingsState, cameraSettings: CameraSettingsModel) => {
          const newState = { ...state };
          newState[cameraSettings.id] = cameraSettings;
          return newState;
        },
        {},
      );
    },
    {
      enabled: false,
      cacheTime: Infinity,
      placeholderData: {},
    },
  );
};

export default useCamerasQuery;
