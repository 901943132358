import React, { ReactNode, useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { useQueryClient } from '@tanstack/react-query';
import AppBar, { AppBarSpacer } from './AppBar';
import NavigationMenu from './NavigationMenu';
import useCamerasQuery, {
  getQueryKey as getCamerasQueryKey,
} from '@/features/camera/hooks/useCamerasQuery';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import PageFooter from './PageFooter';

const DRAWER_WIDTH = 300;
export const CONTAINER_PADDING = 0;

type PageMenuRenderFunction = (open: boolean, setOpen: (open: boolean) => unknown) => ReactNode;

type Props = {
  children: ReactNode;
  title: string;
  renderPageMenu?: PageMenuRenderFunction;
  renderSummaryData?: () => ReactNode;
  pageMenuWidth?: number;
};

const AppPage = ({
  children,
  title,
  renderPageMenu,
  renderSummaryData,
  pageMenuWidth = 0,
}: Props) => {
  const gameId = useCurrentGameId();
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [pageMenuOpen, setPageMenuOpen] = useState(renderPageMenu !== undefined);
  const cameraSettingsStateQuery = useCamerasQuery();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(getCamerasQueryKey(gameId));
    cameraSettingsStateQuery.refetch();
  }, []);

  return (
    <Box>
      <NavigationMenu open={navigationOpen} setOpen={setNavigationOpen} width={DRAWER_WIDTH} />

      <AppBar
        title={title}
        navigationOpen={navigationOpen}
        setNavigationOpen={setNavigationOpen}
        setPageMenuOpen={setPageMenuOpen}
        pageMenuOpen={pageMenuOpen}
        pageMenuWidth={pageMenuWidth}
        hasPageMenu={renderPageMenu !== undefined}
        renderSummaryData={renderSummaryData}
      />
      <AppBarSpacer />

      <Box
        component="main"
        sx={(theme) => ({
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(pageMenuOpen && {
            width: `calc(100% - ${pageMenuWidth}px)`,
            marginRight: `${pageMenuWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        })}
        px={CONTAINER_PADDING}
      >
        {children}
      </Box>

      {renderPageMenu && renderPageMenu(pageMenuOpen, setPageMenuOpen)}

      {false && <PageFooter />}
    </Box>
  );
};

AppPage.defaultProps = {
  renderPageMenu: undefined,
  renderSummaryData: undefined,
  pageMenuWidth: 0,
};

export default AppPage;
