import { useMutation, useQueryClient } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { del } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import { getQueryKey } from './useAllClipsQuery';
import { getQueryKey as currentGameClipsQuery } from './useCurrentGameClipsQuery';

const useDeleteClipMutation = () => {
  const queryClient = useQueryClient();
  const gameId = useCurrentGameId();

  return useMutation(
    async (clipId: number) => {
      await del(`clips/${clipId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getQueryKey());
        queryClient.invalidateQueries(currentGameClipsQuery(gameId));
      },
    },
  );
};

export default useDeleteClipMutation;
