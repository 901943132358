import { useSnackbar } from 'notistack';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { put } from '@truefit/http-utils';
import { Tracking } from '@/shared/services/tracking';

import { AutoIsoId } from '../../features/autoIso/types';
import useCamerasQuery from '../../features/camera/hooks/useCamerasQuery';
import {
  ALL_CAMERAS_VIDEO_SOURCE,
  CameraSettingsModel,
} from '../../features/camera/types';
import { CameraSettingsState } from '../../features/camera/types/CameraSettingsState';
import { ServerState } from '../types/ServerState';
import useCurrentGameId from './useCurrentGameId';

const DEFAULT_CAMERA_SETTINGS_STATE: CameraSettingsState = {};

/** Value used on the server to represent that no tracking ID is set. */
export const NO_TRACKING_ID = 'none';
export const DEFAULT_SERVER_STATE: ServerState = {
  cameraSettingsState: DEFAULT_CAMERA_SETTINGS_STATE,
  videoSourceCameraId: ALL_CAMERAS_VIDEO_SOURCE,
  locked: false
};

const handleTrackingEntityUpdated = (
  currentState: CameraSettingsState,
  trackingId: AutoIsoId,
): CameraSettingsState => {
  const newState = { ...currentState };
  Object.keys(newState).forEach((cameraId) => {
    newState[cameraId].trackingId = trackingId;
  });

  return newState;
};

/**
 * Supplies state tracking on top of the {@link useServerConnection} hook.
 * @returns functions for altering the server's state
 */
const useServerState = () => {
  const gameId = useCurrentGameId();

  // Since the API currently doesn't provide a way for us to know what video source the
  // server is starting with when the app loads, we need to manage the cameras query here.
  const cameraSettingsStateQuery = useCamerasQuery();
  const [serverState, setServerState] = useState<ServerState>(DEFAULT_SERVER_STATE);
  const { enqueueSnackbar } = useSnackbar();

  const setVideoSourceCameraId = useCallback(
    (cam: any, source = 'Automatic') => {
      (async () => {

        let camera = cam;
        if (typeof camera === 'string') camera = { id: cam };
        const { id } = camera;
        Tracking.getInstance().track('Switch View', {
          source,
          category: 'Controls',
          camera,
          type: id === 'quad' ? 'Quad' : 'Single',
        });

        try {
          // change angle of the camera
          // set the camera...
          await put('cameras/view', { id });

          setServerState((currentState) => ({
            ...currentState,
            videoSourceCameraId: id,
          }));
        } catch (e) {
          console.error(e);
        }
      })();
    },
    [enqueueSnackbar],
  );

  const setTrackingEntityId = useCallback(async (ent: any, receivedSource?: string) => {
    let source: string = receivedSource;
    if (!ent) return;
    if (ent === 'none') {
      source = 'Manual Tracking';
    }

    let entity = ent;

    if (ent && !ent.id) {
      entity = { id: ent };
    }
    if (entity.id === 'none') {
      entity.type = 'Manual';
    }
    if (parseInt(entity.id, 10) === 1) {
      entity.type = 'BallOrPuck';
    }

    const { id } = entity;

    await put('tracking/entity', { entityId: id });

    if (source) {
      Tracking.getInstance().track('Switch Tracked Entity', {
        category: 'Iso',
        source,
        entity,
      });
    }

    setServerState((currentState) => ({
      ...currentState,
      cameraSettingsState: handleTrackingEntityUpdated(currentState.cameraSettingsState, id),
    }));
  }, []);

  useEffect(() => {
    cameraSettingsStateQuery.refetch();
  }, []);

  useEffect(() => {
    // Since the API currently doesn't provide a way for us to know what video source the
    // server is starting with when the app loads, and since we can't count on it being
    // same every time (a user could change it and then refresh their browser), and since
    // storing it in local state will introduce other bugs we want to force the server to
    // switch to quad view when the app first loads so that we know the state of the server.
    setVideoSourceCameraId(ALL_CAMERAS_VIDEO_SOURCE);
    setTrackingEntityId({ id: NO_TRACKING_ID });
  }, [setVideoSourceCameraId]);

  useEffect(() => {
    setServerState((currentState) => ({
      ...currentState,
      cameraSettingsState: cameraSettingsStateQuery.data || DEFAULT_CAMERA_SETTINGS_STATE,
    }));
  }, [gameId, cameraSettingsStateQuery.data]);

  const singleCurrentlyTrackedObjectId = useMemo(() => {
    if (!serverState.cameraSettingsState) {
      return NO_TRACKING_ID;
    }

    const cameraId = serverState.videoSourceCameraId;
    if (cameraId && cameraId !== ALL_CAMERAS_VIDEO_SOURCE) {
      return serverState.cameraSettingsState[cameraId]?.trackingId || NO_TRACKING_ID;
    }

    // If no camera is selected as the current video source (i.e. you're in quad
    // view mode) then for situations that aren't camera-specific (e.g. the playing
    // surface view), just show one of the currently tracked objects rather than
    // saying nothing is selected. Since 95% of the time the cameras will all be
    // tracking the same object (according to our current impl) this impl will only
    // rarely fail to show the complete picture of what's being tracked.
    return (
      Object.values(serverState.cameraSettingsState).filter(
        (c: CameraSettingsModel) => c.trackingId && c.trackingId !== NO_TRACKING_ID,
      )[0]?.trackingId || NO_TRACKING_ID
    );
  }, [serverState.cameraSettingsState, serverState.videoSourceCameraId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const serverStateAndFunctions = {
    cameraSettings: serverState.cameraSettingsState[serverState.videoSourceCameraId],
    cameraSettingsState: serverState.cameraSettingsState,
    setTrackingEntityId,
    setVideoSourceCameraId,
    singleCurrentlyTrackedObjectId,
    videoSourceCameraId: serverState.videoSourceCameraId,
  };

  const serverStateAndFunctionsCopyRef = useRef(serverStateAndFunctions);

  useEffect(() => {
    serverStateAndFunctionsCopyRef.current = serverStateAndFunctions;
  }, [serverStateAndFunctions]);

  const getServerStateAndFunctions = useCallback(() => serverStateAndFunctionsCopyRef.current, []);

  return { ...serverStateAndFunctions, getServerStateAndFunctions };
};

export type ServerStateAndFunctions = ReturnType<typeof useServerState>;
export type ServerStateAndFunctionWithoutGetter = Omit<
  ReturnType<typeof useServerState>,
  'getServerStateAndFunctions'
>;

export default useServerState;
