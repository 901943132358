import { CognitoUser } from 'amazon-cognito-identity-js';
import { NewPasswordParams, UpdateCognitoContext } from './types';

// eslint-disable-next-line max-len
const completeNewPasswordChallenge = (user: CognitoUser, updateCognitoContext: UpdateCognitoContext) => ({ password, onSuccess, onFailure }: NewPasswordParams) => {
  user.completeNewPasswordChallenge(
    password,
    {},
    {
      onSuccess: (userSession) => {
        updateCognitoContext({ userSession });
        onSuccess();
      },

      onFailure: (err) => {
        onFailure(err.message);
      },
    },
  );
};
export default completeNewPasswordChallenge;
