import {  Cams, Store, StoreHookReturn } from '@/types/camspec';
import { PtzMessage } from '../types';
import useServerStateContext from './useServerStateContext';
import useWebSocket from './useWebSocket';


// using a module-scoped store because we need to track this in such a way that
// is shareable, but does not trigger state updates.
let store: Store = {};
const cams: Cams = {};

export const usePanStore = (): StoreHookReturn => {

  const handleChange = (cameraId: string, value: number) => {
    store = {
      ...store,
      [cameraId]: value,
    };
  };


  const socket = useWebSocket<PtzMessage>('/ptz/pan', {
    onMessage: (message) => {
      handleChange(message.cameraId, message.value);
    },
  });



  const init = () => {

    Object.keys(store).forEach((cameraId) => {
      socket.send({ cameraId, value: 0 });
      handleChange(cameraId, 0);
    })
  };

  const useServerStateContextHook = useServerStateContext();
  if (!useServerStateContextHook) return { init, reset: init };
  const { getServerStateAndFunctions } = useServerStateContextHook;

  const setMaximum = (value: number) => {
    const cameraId = getServerStateAndFunctions().videoSourceCameraId;
    cams[cameraId].max = value;
  }

  const setValue = (value: number) => {
    if (!Number.isFinite(value)) {
      return;
    }

    const cameraId = getServerStateAndFunctions().videoSourceCameraId;
    socket.send({ cameraId, value });
    handleChange(cameraId, value);
  };

  const getValue = () => {
    const cameraId = getServerStateAndFunctions().videoSourceCameraId;
    return store[cameraId];
  };

  return { getValue, setValue, init, reset: init, setMaximum, panStore: store};
};
