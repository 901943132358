import setHttpAuth from "./setHttpAuth";
import { RefreshTokenParams } from "./types";

const setUpRefreshToken = (refreshTokenParams: RefreshTokenParams) => {
  const {
    user, skew, updateCognitoContext, userSession,
  } = refreshTokenParams;
  const refreshToken = userSession.getRefreshToken();
  const expiration: number = userSession.getAccessToken().getExpiration();
  // by default 5 minutes before the expiry of the access token we check the renewal
  const skewInMillis = skew ? skew * 1000 : 30 * 10 * 1000;
  const expirationInMillis = expiration * 1000;
  const now = new Date().getTime();
  const timeout = expirationInMillis - now - skewInMillis;
  console.debug(`Refresh token in [${timeout / 1000}] seconds`);
  setTimeout(() => {
    user.refreshSession(refreshToken, (err, newUserSession) => {
      if (err) {
        console.error('Failed to update the session, leaving ! ');
        updateCognitoContext({
          userAttributes: undefined,
          user: undefined,
          userSession: undefined,
          authenticated: false,
          newPasswordRequired: false,
        });
      } else {
        console.debug(
          '[cognito] Successfully aquired a new token, and updating http and websocket queries',
        );

        setHttpAuth(newUserSession);
        setUpRefreshToken({
          updateCognitoContext,
          user,
          userSession: newUserSession,
        });
      }
    });
  }, timeout);
};

export default setUpRefreshToken;
