import differenceInSeconds from "date-fns/differenceInSeconds";

export const getDuration = (start: Date, end: Date) => {
    const d = differenceInSeconds(end, start);
    if (Number.isNaN(d)) return '';
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    // const s = Math.floor((d % 3600) % 60);
    if (h === 0 && m === 0) return '0m';
    if (h === 0) return `${m}m`;
    if (m === 0) return `${h}h`;
    return `${h}h ${m}m`;
  };