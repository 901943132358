import { Dispatch } from 'redux';

import { createAction } from '@reduxjs/toolkit';
import { del } from '@truefit/http-utils';
import { handleApiError } from '@/shared/services';

export enum SignOutActions {
  Completed = 'SIGN_OUT/COMPLETED',
}

const completed = createAction(SignOutActions.Completed);

export const signOut = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    await del('authentication/sign-out');
  } catch (e) {
    handleApiError(e);
  } finally {
    dispatch(completed());
  }
};
