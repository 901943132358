import isValid from "date-fns/isValid";

import { DateTime } from 'luxon';

import { get } from "@truefit/http-utils";

import { GetGamesResult } from "../types/GetGamesResult";
import { FormValues as GamesFormValues } from '../components/GamesForm';

type QueryParams = GamesFormValues & {
  limit: number;
  offset: number;
};


export const fetchGames = ({ date, limit, offset, sort, streamType, team }: QueryParams) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('limit', limit.toString());
  urlSearchParams.set('offset', offset.toString());
  urlSearchParams.set('sort', sort);

  if (team) {
    urlSearchParams.set('team', team);
  }

  if (date && isValid(date)) {
    const strDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const [month, day, year] = strDate.split('/');

    const EasternDate = DateTime.fromObject(
      { day: parseInt(day, 10), month: parseInt(month, 10), year: parseInt(year, 10) },
      { zone: 'America/Toronto' }, // force selector to the ET timezone
    );

    urlSearchParams.set('date', EasternDate.toString());
  }

  urlSearchParams.set('streamType', streamType);

  return get<GetGamesResult>(`games?${urlSearchParams.toString()}`);
};