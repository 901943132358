import { useCallback, useEffect, useState } from 'react';
import { allTimezones } from 'react-timezone-select';
import useCognito from '@/features/authentication/hooks/useCognito';
import useCurrentUser, { User } from './useCurrentUser';
import useUpdateUser from './useUpdateUser';

export type UserState = {
  user: User;
  setOpen: (open?: boolean) => void;
  updateUserTimezone: (tz: string) => void;
  isLoading: boolean;
};
const useUserState = () => {
  const updateUserMutation = useUpdateUser();
  const currentUser = useCurrentUser();
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const cognito = useCognito();
  const [user, setUser] = useState<User>({ tz: browserTz });
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (currentUser.status === 'success') {
      const {
        data: { data },
      } = currentUser;
      setUser({
        tz: allTimezones[data.tz] ? data.tz : browserTz,
      });
    }
  }, [currentUser.fetchStatus]);

  useEffect(() => {
    if (cognito.authenticated) {
      currentUser.refetch();
    }
  }, [cognito.authenticated]);

  const updateUserTimezone = useCallback(async (tz: string) => {
    await updateUserMutation.mutateAsync({ tz });
    await currentUser.refetch();
  }, []);

  return {
    user,
    open,
    updateUserTimezone,
    setOpen,
    isLoading: currentUser.fetchStatus !== 'idle',
  };
};

export default useUserState;
