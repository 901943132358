import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

export const queryKey = ['GameTeams'];

const useGameTeamsQuery = () => useQuery(
  queryKey,
  async () => {
    const { data } = await get('teams');
    return data;
  },
  { placeholderData: [] },
);

export default useGameTeamsQuery;
