import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

export const queryKey = (gameId: number) => ['SwitchGame', gameId];

const useGameSwitchQuery = (gameId: number) => useQuery(
  queryKey(gameId),
  async () => get(`/games/${gameId}/switch`),
  {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  },
);

export default useGameSwitchQuery;
