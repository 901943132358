import { addSeconds, differenceInMilliseconds, subMilliseconds } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import FastRewindRoundedIcon from '@mui/icons-material/FastRewindRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import {
  Divider,
  Stack,
  Tooltip as BaseTooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KeyboardShortcut, KeyboardShortcuts } from '@/shared/constants';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import useJumpFrame from '@/shared/hooks/useJumpFrame';
import useKeyboardShortcutsDialog from '@/shared/hooks/useKeyboardShortcutsDialog';
import { usePlaybackSpeedState } from '@/shared/hooks/usePlaybackSpeed';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { useTimestampState } from '@/shared/hooks/useTimestamp';
import { useTimestampEndState } from '@/shared/hooks/useTimestampEnd';
import { useTimestampStartState } from '@/shared/hooks/useTimestampStart';
import { displayInterval } from '@/shared/services';
import displayWallClock from '@/shared/services/displayWallClock';
import { Tracking } from '@/shared/services/tracking';

import { ALL_CAMERAS_VIDEO_SOURCE } from '../../camera/types';
import useGameDetailsQuery from '../../games/hooks/useGameDetailsQuery';
import usePlayerHotkeys from '../hooks/usePlayerHotkeys';
import OneFrameBackward from './OneFrameBackwardIcon';
import OneFrameForward from './OneFrameForwardIcon';
import OneSecondBackward from './OneSecondBackwardIcon';
import OneSecondForward from './OneSecondForwardIcon';
import PlaybackPositionSlider from './PlaybackPositionSlider';
import PlaybackSpeedSelectionButton from './PlaybackSpeedSelectionButton';
import PlayerCameraFeedSelectionButton from './PlayerCameraFeedSelectionButton';
import { Videocam } from '@mui/icons-material';

type Props = {
  exitFullscreen: () => Promise<void>;
  isFullscreen: boolean;
  requestFullscreen: () => Promise<void>;
  onStatsToggle?: () => unknown;
};

type PlayerControlProps = {
  children: React.ReactNode;
  color?: string;
  disabled?: boolean;
  onClick: () => unknown;
  title: string;
};

const Tooltip = (props: TooltipProps) => (
  <BaseTooltip arrow placement="top" PopperProps={{ disablePortal: true }} {...props} />
);

const PRIMARY_COLOR = '#ffffff';
const SECONDARY_COLOR = 'b8b8b8';

const PlayerControl = ({
  children,
  color = PRIMARY_COLOR,
  disabled,
  onClick,
  title,
}: PlayerControlProps) => (
  <Box>
    <Tooltip title={title} arrow>
      <IconButton size="small" onClick={onClick} sx={{ color }} disabled={disabled}>
        {children}
      </IconButton>
    </Tooltip>
  </Box>
);

PlayerControl.defaultProps = {
  color: PRIMARY_COLOR,
  disabled: false,
};

const displayCommand = (shortcut: KeyboardShortcut) => `${shortcut.command} (${shortcut.hotkey})`;

const PlayerControls = ({
  requestFullscreen,
  exitFullscreen,
  isFullscreen,
  onStatsToggle,
}: Props) => {
  const [showingBookmarks, setShowingBookmarks] = useState(true);
  const gameId = useCurrentGameId();
  const gameQuery = useGameDetailsQuery(gameId);
  const [timestamp, setTimestamp] = useTimestampState();
  const [endTime] = useTimestampEndState();
  const [startTime] = useTimestampStartState();
  const [isLiveEdge, setIsLiveEdge] = useState(false);
  const { setVideoSourceCameraId, videoSourceCameraId } = useServerStateContext();
  const { jumpFrame } = useJumpFrame();
  const { livePlaybackDelay, liveTimestampOffset, ffAndRw } = useFlags();

  const playbackPositionSeconds = Math.round(differenceInMilliseconds(timestamp, startTime) / 1000);
  const endPositionSeconds = Math.round(differenceInMilliseconds(endTime, startTime) / 1000);
  const [playbackSpeed, setPlaybackSpeed] = usePlaybackSpeedState();

  const isPlaying = playbackSpeed !== 0;

  const {
    state: { isEditing },
  } = useClipStateContext();

  const durationLabel = (seconds: number) => {
    try {
      if (startTime)
        return displayInterval({ start: startTime, end: addSeconds(startTime, seconds) });
      return '-';
    } catch (e) {
      console.warn(
        `Warning: could not display duration label for ${seconds} seconds and ${startTime}`,
      );
      console.error(e);
      return '-';
    }
  };

  const handleLiveButtonClick = useCallback(() => {
    console.debug(`::liveedge : liveTimestampOffset ${liveTimestampOffset}`);
    console.debug(`::liveedge : livePlaybackDelay ${livePlaybackDelay}`);
    const sent = subMilliseconds(endTime, liveTimestampOffset);
    console.debug(`::liveedge : current EndTimestamp  ${endTime.getTime()}`);
    console.debug(`::liveedge : sent EndTimestamp ${sent.getTime()}`);
    console.debug(`::liveedge : setTimestamp @ ${new Date().getTime()}`);

    setTimestamp(sent);

    setTimeout(() => {
      setPlaybackSpeed(1);
    }, livePlaybackDelay);
  }, [setTimestamp, endPositionSeconds, timestamp, liveTimestampOffset, livePlaybackDelay]);

  const handleJump = useCallback(
    (jumpSeconds: number) => {
      const newPosition = addSeconds(timestamp, jumpSeconds);
      setTimestamp(newPosition);
    },
    [setTimestamp, timestamp],
  );

  const handlePlayPause = (e: Event) => {
    Tracking.getInstance().track('Playback', {
      category: 'Controls',
      type: isPlaying ? 'Pause' : 'Play',
      source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
    });
    if (!isPlaying) {
      setPlaybackSpeed(1);
    } else {
      setPlaybackSpeed(0);
    }
  };

  const handleJumpForwardOneSecond = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        unit: 'Second',
        type: 'Jump',
        direction: 'Forward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      handleJump(1);
    },
    [handleJump],
  );
  const handleJumpBackwardOneSecond = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        unit: 'Second',
        type: 'Jump',
        direction: 'Backward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      handleJump(-1);
    },
    [handleJump],
  );
  const handleJumpForwardOneFrame = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        unit: 'Frame',
        type: 'Jump',
        direction: 'Forward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      jumpFrame(1);
    },
    [jumpFrame],
  );
  const handleJumpBackwardOneFrame = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        type: 'Jump',
        unit: 'Frame',
        direction: 'backward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      jumpFrame(-1);
    },
    [jumpFrame],
  );

  const keyboardShortcutsModal = useKeyboardShortcutsDialog();

  usePlayerHotkeys(
    handlePlayPause,
    handleJumpForwardOneSecond,
    handleJumpBackwardOneSecond,
    handleJumpForwardOneFrame,
    handleJumpBackwardOneFrame,
  );
  const inLiveMode = gameQuery?.data?.isLive;

  useEffect(() => {
    if (inLiveMode) {
      // 2 seconds after loading the game
      setTimeout(() => {
        handleLiveButtonClick();
      }, 2 * 1000);
    }
  }, []);

  useEffect(() => {
    setIsLiveEdge(playbackPositionSeconds / endPositionSeconds > 0.95);
  }, [playbackPositionSeconds, endPositionSeconds]);

  const isQuad = videoSourceCameraId === ALL_CAMERAS_VIDEO_SOURCE;

  return (
    <>
      <PlaybackPositionSlider showingBookmarks={showingBookmarks} />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ overflowX: 'auto' }}
      >
       
          <Stack direction="row" alignItems="center" spacing={1} px={2}>
            <PlayerControl
              title={displayCommand(KeyboardShortcuts.MoveFrameBackward)}
              onClick={handleJumpBackwardOneFrame}
            >
              <OneFrameBackward />
            </PlayerControl>

            <PlayerControl
              title={displayCommand(KeyboardShortcuts.ShuttleBackward)}
              onClick={handleJumpBackwardOneSecond}
            >
              <OneSecondBackward />
            </PlayerControl>

            {!!ffAndRw && (
              <PlayerControl
                title="Rewind"
                onClick={() => {
                  setPlaybackSpeed(-6);
                  Tracking.getInstance().track('Playback', {
                    category: 'Controls',
                    type: 'Rewind',
                  });
                }}
              >
                <FastRewindRoundedIcon />
              </PlayerControl>
            )}

            <PlayerControl
              title={displayCommand(KeyboardShortcuts.PlayPause)}
              onClick={handlePlayPause}
            >
              {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
            </PlayerControl>

            {!!ffAndRw && (
              <PlayerControl
                title="Fast Forward"
                onClick={() => {
                  Tracking.getInstance().track('Playback', {
                    category: 'Controls',
                    type: 'Fast Forward',
                  });
                  setPlaybackSpeed(6);
                }}
              >
                <FastForwardRoundedIcon />
              </PlayerControl>
            )}

            <PlayerControl
              title={displayCommand(KeyboardShortcuts.ShuttleForward)}
              onClick={handleJumpForwardOneSecond}
            >
              <OneSecondForward />
            </PlayerControl>

            <PlayerControl
              title={displayCommand(KeyboardShortcuts.MoveFrameForward)}
              onClick={handleJumpForwardOneFrame}
            >
              <OneFrameForward />
            </PlayerControl>

            <Stack direction="row" spacing={2} sx={{ pl: 4 }}>
              <Typography noWrap>{displayWallClock(timestamp)}</Typography>
              <Typography>|</Typography>
              <Typography noWrap>{durationLabel(endPositionSeconds)}</Typography>
            </Stack>
          </Stack>
       
    
        <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}>
          <Stack direction="row" alignItems="center">
            {inLiveMode && (
              <Stack
                direction="row"
                spacing={1}
                style={{ marginRight: 20, cursor: 'pointer' }}
                onClick={handleLiveButtonClick}
              >
                <CircleRoundedIcon color={isLiveEdge ? 'error' : 'disabled'} sx={{ width: 16 }} />
                <Typography>Live</Typography>
              </Stack>
            )}
            <PlayerControl
              title={`${showingBookmarks ? 'Hide' : 'Show'} Bookmarks & Clips`}
              onClick={() => setShowingBookmarks(!showingBookmarks)}
              color={showingBookmarks ? PRIMARY_COLOR : SECONDARY_COLOR}
            >
              {showingBookmarks ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            </PlayerControl>

            {!!ffAndRw && (
              <Tooltip title="Playback Speed" arrow>
                <Box>
                  <PlaybackSpeedSelectionButton disabled={isEditing} />
                </Box>
              </Tooltip>
            )}

            <PlayerControl
              title="Keyboard Shortcuts"
              onClick={() => keyboardShortcutsModal.show()}
              color={keyboardShortcutsModal.showing ? PRIMARY_COLOR : SECONDARY_COLOR}
            >
              <KeyboardIcon />
            </PlayerControl>
            <PlayerControl title="Streaming Stats" onClick={onStatsToggle}>
              <InfoOutlinedIcon />
            </PlayerControl>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1" noWrap>
              Select View:
            </Typography>

            <PlayerControl
              title="Quad Camera"
              onClick={() =>
                setVideoSourceCameraId(ALL_CAMERAS_VIDEO_SOURCE, 'Video Player Toolbar')
              }
              color={isQuad ? PRIMARY_COLOR : SECONDARY_COLOR}
            >
              <GridViewRoundedIcon />
            </PlayerControl>

            <Tooltip title="Single Camera" arrow>
              <Box>
                <PlayerCameraFeedSelectionButton />
              </Box>
            </Tooltip>

            {isFullscreen ? (
              <PlayerControl title="Exit Fullscreen" onClick={exitFullscreen} color={PRIMARY_COLOR}>
                <FullscreenExitRoundedIcon />
              </PlayerControl>
            ) : (
              <PlayerControl title="Fullscreen" onClick={requestFullscreen} color={SECONDARY_COLOR}>
                <FullscreenRoundedIcon />
              </PlayerControl>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

PlayerControls.defaultProps = {
  onStatsToggle: () => {},
};

export default PlayerControls;
