import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import setHttpAuth from './setHttpAuth';
import setUpRefreshToken from './setupRefreshToken';
import getUserAttributes from './getUserAttributes';
import { SignInParams, UpdateCognitoContext } from './types';

// eslint-disable-next-line max-len
const authenticateUser = (updateCognitoContext: UpdateCognitoContext, userPool: CognitoUserPool) => ({
  username, password, onSuccess, onFailure, newPasswordRequired,
}: SignInParams) => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  user.authenticateUser(authDetails, {
    onSuccess: (userSession) => {
      getUserAttributes({
        user,
        onFailure,
        onSuccess(userAttributes) {
          updateCognitoContext({
            userAttributes,
            user,
            userSession,
            authenticated: true,
            newPasswordRequired: false,
          });

          setHttpAuth(userSession);
          setUpRefreshToken({
            updateCognitoContext,
            user,
            userSession,
          });
          onSuccess();
        },
      });
    },

    onFailure: (err) => {
      onFailure(err.message);
    },

    newPasswordRequired: (userAttributes) => {
      updateCognitoContext({
        user,
        userAttributes,
        newPasswordRequired: true,
      });

      newPasswordRequired();
    },
  });
};

export default authenticateUser;
