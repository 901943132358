/* eslint-disable no-console */
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  styled,
  Button,
} from '@mui/material';
import ReconnectingEventSource from 'reconnecting-eventsource';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { del } from '@truefit/http-utils';

import useCognito from '../../authentication/hooks/useCognito';
import { GetGlobalSSEUrl } from '@/util';
import TimeMachineStatusProvider from '@/features/timeMachine/components/TimeMachineStatusProvider';
import { useITMSession } from '@/features/authentication/hooks';
import { AnimatedCosmLogo, ExtendSessionDialog } from '@/shared/components';

import CXVideoHub from '@/assets/cx_video_hub.png';
import useUserLeague from '@/features/authentication/hooks/useUserLeague';
import { getLeagueLogo } from '@/shared/services/getLeagueLogo';

import BackgroundImage from '@/assets/images/loading-bg.png';

type Props = {
  children: ReactNode;
};

enum LocalSessionState {
  NOT_LOADED,
  LOADING,
  NO_LOCAL_SESSION,
  HAS_LOCAL_SESSION,
  AUTHENTICATED,
  READY,
}

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const AuthenticateRoute = ({ children }: Props) => {
  const history = useHistory();
  const cognito = useCognito();
  const itmSession = useITMSession();
  const [newSession, setNewSession] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [progressMessage, setProgressMessage] = useState<string>('Initializing');
  const [sessionHandler, setSessionHandler] = useState<string>('');
  const [cognitoSession, setCognitoSession] = useState<CognitoUserSession | null>(null);
  const [isExpiringSession, setIsExpiringSession] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(null);
  const [clickCount, setClickCount] = useState(0);
  const [displaySecondaryMessage, setDisplaySecondaryMessage] = useState<boolean>(false);
  const [secondaryMessage, setSecondaryMessage] = useState<string>(
    "You've unlocked the secret message!",
  );

  const [sessionState, setSessionState] = useState<LocalSessionState>(
    cognito.authenticated ? LocalSessionState.AUTHENTICATED : LocalSessionState.NOT_LOADED,
  );
  const [leagueLogo, setLeagueLogo] = useState<string>(null);
  const location = useLocation();
  const sse = useRef<ReconnectingEventSource | null>(null);

  const getUserLeague = useUserLeague(email);

  const handleClick = () => {
    setClickCount(clickCount + 1);

    if (clickCount + 1 === 3) {
      setDisplaySecondaryMessage(true);
    }
  };

  const handleSignOut = () => {
    cognito.signOut({
      async onSuccess() {
        await del('session');
        history.push('/sign-in');
      },

      onFailure(e) {
        console.error(e);
        history.push('/sign-in');
      },
    });
  };

  const getRealtimeData = useCallback((data, sessionId) => {
    // process the data here,
    const { event, payload } = data;

    if (event === 'initialized') {
      if (!newSession) {
        itmSession.refetch();
        setNewSession(true);
      }
      return null;
    }

    if (payload.sessionId === sessionId) {
      setSecondaryMessage(JSON.stringify(payload, null, 2));

      if (event === 'session-deploy-error') {
        setProgressMessage(
          'Additional Player Unavailable - We are unable to load a new video playback server at this time.  Support has been notified and please try again later.',
        );
        setLoading(false);
        throw new Error(payload.message);
      }

      if (event === 'session-deploy-progress') {
        setProgressMessage(payload.message);
        return null;
      }

      if (event === 'session-ready') {
        setSessionState(LocalSessionState.READY);
        return null;
      }

      if (event === 'session-handler') {
        setSessionHandler(payload.handler);
        return null;
      }

      if (event === 'session-ended') {
        const search = `?returnUrl=${encodeURIComponent(location.pathname)}`;
        history.push(`/sign-in${search}`);
      }

      if (event === 'session-expiring') {
        setIsExpiringSession(true);
      }
    }

    return null;
  }, []);

  useEffect(() => {
    if (getUserLeague.data && getUserLeague.data.name) {
      setLeagueLogo(getLeagueLogo(getUserLeague.data.id));
    } else {
      setLeagueLogo(null);
    }
  }, [getUserLeague.data]);

  useEffect(() => {
    getUserLeague.refetch();
  }, [email]);

  useEffect(() => {
    if (sessionHandler) {
      cognito.setHttpAuth(cognitoSession, sessionHandler);
      cognito.updateCognitoContext({ sessionHandler });
    }
  }, [sessionHandler]);

  useEffect(() => {
    if (sessionState === LocalSessionState.NOT_LOADED) {
      cognito.getSession({
        onSuccess(userSession: CognitoUserSession) {
          setCognitoSession(userSession);
          setSessionState(LocalSessionState.HAS_LOCAL_SESSION);
        },

        onFailure() {
          setSessionState(LocalSessionState.NO_LOCAL_SESSION);
        },
      });
    }
  }, [cognito, sessionState]);

  useEffect(() => {
    if (cognito.authenticated) {
      cognito.getSession({
        onSuccess(userSession: CognitoUserSession) {
          setCognitoSession(userSession);
          if (userSession?.getIdToken()) {
            setEmail(userSession?.getIdToken().payload.email);

            const SSEHref = GetGlobalSSEUrl();
            if (!sse.current) {
              sse.current = new ReconnectingEventSource(SSEHref);
              sse.current.onmessage = (e) =>
                getRealtimeData(JSON.parse(e.data), cognito.user.getUsername());
              sse.current.onerror = () => {
                // noop
              };
            }
          }
          setSessionState(LocalSessionState.HAS_LOCAL_SESSION);
        },

        onFailure() {
          setSessionState(LocalSessionState.NO_LOCAL_SESSION);
        },
      });
    }

    return () => {
      if (sse.current) {
        sse.current.close();
        sse.current = null;
      }
    };
  }, [cognito.authenticated]);

  if (
      sessionState === LocalSessionState.NOT_LOADED ||
      sessionState === LocalSessionState.LOADING ) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <Stack>
          <Item> Loading...</Item>
          <Item>
            <CircularProgress color="inherit" />
          </Item>
        </Stack>
      </Backdrop>
    );
  }

  if (sessionState === LocalSessionState.NO_LOCAL_SESSION) {
    const search = `?returnUrl=${encodeURIComponent(location.pathname)}`;
    return <Redirect to={{ pathname: '/sign-in', search }} />;
  }

  if (
    sessionState === LocalSessionState.AUTHENTICATED ||
    sessionState === LocalSessionState.HAS_LOCAL_SESSION
  ) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <Paper
          sx={{
            backgroundColor: 'transparent',
            borderRadius: 2,
            shadow: 24,
            px: 3,
            pt: 2,
            pb: 1,
            minWidth: 700,
            minHeight: 700,
            backgroundImage: `url(${BackgroundImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <AnimatedCosmLogo leagueLogo={leagueLogo} height={40} />

          <img src={CXVideoHub} alt="CXVideo Hub" style={{ height: 15, marginTop: 15 }} />
          <Typography variant="subtitle2" sx={{ mb: 3, mt: 1 }}>
            with AUTO-ISO&trade;
          </Typography>
          {loading && (
            <Item sx={{ mt: 5, mb: 10 }}>
              <CircularProgress color="inherit" />
            </Item>
          )}
          <Box sx={{ maxWidth: 550, textAlign: 'center' }} onClick={handleClick}>
            {progressMessage}
          </Box>

          <Box sx={{ maxWidth: 550, textAlign: 'center', pt: 4 }}>
            <Button onClick={handleSignOut}>{loading ? 'Cancel Login' : 'Back'}</Button>
          </Box>

          {displaySecondaryMessage && (
            <Box
              sx={{
                maxWidth: 550,
                textAlign: 'center',
                mt: 2,
                color: 'orange',
                fontSize: 10,
                fontFamily: 'monospace',
              }}
            >
              {secondaryMessage}
            </Box>
          )}
        </Paper>
      </Backdrop>
    );
  }

  return (
    <TimeMachineStatusProvider>
      <ExtendSessionDialog open={isExpiringSession} onClose={() => setIsExpiringSession(false)} />
      {children}
    </TimeMachineStatusProvider>
  );
};

export default AuthenticateRoute;
