import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { debounce } from 'lodash';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { nameofFactory } from '@/shared/services';
import { Tracking } from '@/shared/services/tracking';
import { LeagueContext } from './LeagueContext';

import {
  FormSubmitBar,
  FormValidationSummary,
  HookFormPasswordField,
  HookFormTextField,
} from '../../forms/components';
import useCognito from '../hooks/useCognito';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import useUserLeague from '../hooks/useUserLeague';
import { getLeagueLogo } from '@/shared/services/getLeagueLogo';

type SignInFormValues = {
  username: string;
  password: string;
};

const nameOf = nameofFactory<SignInFormValues>();

const validationSchema = Yup.object().shape({
  [nameOf('username')]: Yup.string().required().max(256).label('Username'),
  [nameOf('password')]: Yup.string().required().max(50).label('Password'),
});

const useSignIn = () => {
  const cognito = useCognito();
  const queryString = new URLSearchParams(useLocation().search);
  const returnUrl = queryString.get('returnUrl');
  const history = useHistory();
  const form = useForm<SignInFormValues>({
    defaultValues: {
      [nameOf('username')]: '',
      [nameOf('password')]: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const [generalValidationErrors, setGeneralValidationErrors] = React.useState<string[]>([]);

  const handleSubmit = form.handleSubmit(
    (formValues) =>
      new Promise((resolve) => {
        cognito.authenticateUser({
          username: formValues.username,
          password: formValues.password,

          onSuccess() {
            Tracking.getInstance().signIn();
            history.push(returnUrl ?? '/');
            resolve();
          },

          onFailure(message) {
            setGeneralValidationErrors([message]);
            resolve();
          },

          newPasswordRequired() {
            Tracking.getInstance().track('Reset Password', {
              categort: 'Auth',
            });

            history.push('/reset-password');
            resolve();
          },
        });
      }),
  );

  return { form, handleSubmit, generalValidationErrors };
};

const SignInForm = () => {
  const { form, handleSubmit, generalValidationErrors } = useSignIn();
  const [email, setEmail] = React.useState('');

  const { setLeagueLogo } = useContext(LeagueContext);

  const getUserLeague = useUserLeague(email);

  const handleBlur = async (event) => {
    // replace this with your API call to fetch the logo
    setEmail(event.target.value);
  };

  const realHandleChange = async (event) => {
    // replace this with your API call to fetch the logo
    setEmail(event.target.value);
  };

  const handleChange = debounce(realHandleChange, 200);

  useEffect(() => {
    if (getUserLeague.data && getUserLeague.data.name) {
      setLeagueLogo(getLeagueLogo(getUserLeague.data.id));
    } else {
      setLeagueLogo(null);
    }
  }, [getUserLeague.data]);

  useEffect(() => {
    getUserLeague.refetch();
  }, [email]);

  const { isSubmitting } = form.formState;
  const disabled = isSubmitting;
  useDocumentTitle(`Log In`);

  return (
    <FormProvider {...form}>
      <form noValidate style={{ width: '100%' }}>
        <FormValidationSummary validationErrors={generalValidationErrors} />

        <HookFormTextField
          name={nameOf('username')}
          autoFocus
          required
          label="Email"
          disabled={disabled}
          variant="outlined"
          margin="dense"
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <HookFormPasswordField
          name={nameOf('password')}
          required
          label="Password"
          disabled={disabled}
          variant="outlined"
          margin="dense"
          onChange={handleChange}
          onEnter={handleSubmit}
        />

        <FormSubmitBar
          submitting={isSubmitting}
          buttonText="Sign In"
          onSubmit={handleSubmit}
          mainButtonStyle={{ width: '50%' }}
        />
        <Grid container justifyContent="center" alignItems="center">
          <Button
            component={RouterLink}
            to="/forgot-password"
            color="secondary"
            sx={{ mt: 1, ml: -1 }}
          >
            Forgot password?
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SignInForm;
