export function getMaxPanTilt({ fov, maxFov }): { pan: number; tilt: number } {
  let receivedFov = fov;

  const maxPan = 67;
  const maxTilt = 67;
  
  if(receivedFov>maxFov){
    receivedFov = maxFov;
  }

  if(receivedFov<0){
    receivedFov = 0;
  }
  

  const pan = maxPan / receivedFov / maxFov ;
  const tilt = maxTilt / receivedFov / maxFov;

  
  return {
    pan,
    tilt,
  };
 
}