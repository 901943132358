import { JumpFramesMessage } from '../types';
import useWebSocket from './useWebSocket';

const useJumpFrame = () => {
  const socket = useWebSocket<JumpFramesMessage>('/jump-frame');

  return {
    jumpFrame: (frames: number) => {
      socket.send({ frames });
    },
  };
};

export default useJumpFrame;
