import { combineReducers } from 'redux';

import authentication, { AuthenticationState } from './features/authentication/reducers';

export type ApplicationState = {
  features: {
    authentication: AuthenticationState;
  };
};

const createRootReducer = () => combineReducers({
  features: combineReducers({
    authentication,
  }),
});

export default createRootReducer;
