import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

import { GameTypeMetadataModel } from '../../features/autoIso/types';
import useCurrentGameId from './useCurrentGameId';

export const getQueryKey = (gameId: number) => ['Games', gameId, 'TypeMetadata'];

const useGameTypeMetadataQuery = () => {
  const gameId = useCurrentGameId();

  return useQuery(
    getQueryKey(gameId),
    async () => {
      const response = await get<GameTypeMetadataModel>(`games/${gameId}/metadata`);
      return response.data;
    },
    {
      cacheTime: Infinity,
    },
  );
};

export default useGameTypeMetadataQuery;
