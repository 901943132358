import { CognitoUser } from 'amazon-cognito-identity-js';
import { ConfirmPasswordParams } from './types';

const confirmPassword = (user: CognitoUser) => ({
  verificationCode, newPassword, onSuccess, onFailure,
}: ConfirmPasswordParams) => {
  user.confirmPassword(verificationCode, newPassword, {
    onSuccess() {
      onSuccess();
    },

    onFailure(err) {
      onFailure(err.message);
    },
  });
};

export default confirmPassword;
