import React, { ReactNode, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import { useWheel, useDrag, usePinch } from '@use-gesture/react';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { useZoomStore } from '@/shared/hooks/useZoom';

import { ALL_CAMERAS_VIDEO_SOURCE } from '../../camera/types';
import usePtzVelocity from '@/shared/hooks/usePtzVelocity';
import { getMaxPanTilt } from '@/features/video/utils/getMaxPanTilt';
import { useSpring } from 'framer-motion';

type Props = {
  children: ReactNode;
};

function mapValueToRange(a: number, x1: number, y1: number, x2: number, y2: number): number {
  // Ensure that 'a' is within the range x1 to y1
  a = Math.max(x1, Math.min(y1, a));

  // Perform linear interpolation
  const result = ((a - x1) * (y2 - x2)) / (y1 - x1) + x2;

  return result;
}

function getVelocity(value: number, max?: number): number {
  value = value * 2;
  if (value < 0.01) return 0;
  if (value > max) return max;
  return value;
}

type MaxPTBoundaries = {
  pan: number;
  tilt: number;
};

const MAX_VELOCITY = 1; // degrees/frame
const SIZE = 135;
const MAX_DRAG_DISTANCE = SIZE * 2;

const toVelocity = (dragDistance: number, velocity: number = MAX_VELOCITY) =>
  (dragDistance / MAX_DRAG_DISTANCE) * velocity;

// zoom is 180 (fully zoomed out) to 1 (max zoom in)
const WheelZoom = ({ children }: Props) => {
  const ref = useRef<HTMLElement>();
  const { getValue: getZoom, setValue: setZoom, getMinFov } = useZoomStore();
  const [dragBoundaries, setDragBoundaries] = React.useState<MaxPTBoundaries>(); // [x,y,width,height]

  const { setPtzVelocity } = usePtzVelocity();

  const { videoSourceCameraId: cameraId } = useServerStateContext();

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    setDragBoundaries({ pan: rect.width / 2, tilt: ((rect.width*9)/16) / 2 });
  }, []);

  usePinch(
    (state) => {
      state.event.preventDefault();

      if (!state.active) {
        // ignores the extra event that use-gesture includes to indicate when the gesture ends
        // see
        return;
      }
      if (cameraId === ALL_CAMERAS_VIDEO_SOURCE) {
        return;
      }

      const zoom = getZoom();
      const delta = state.delta[1] * getVelocity(state.velocity[1], 20); // 1 for up, -1 for down
      const newZoom = zoom + delta;
      if (newZoom < 1) return;
      setZoom(newZoom);

      getMaxPanTilt({
        fov: newZoom,
        maxFov: getMinFov(cameraId),
      })
    }
  );

  
  useWheel(
    (state) => {
      state.event.preventDefault();

      if (!state.active) {
        // ignores the extra event that use-gesture includes to indicate when the gesture ends
        // see https://github.com/pmndrs/use-gesture/issues/202
        return;
      }

      if (cameraId === ALL_CAMERAS_VIDEO_SOURCE) {
        return;
      }

      const speed = state.velocity[1];
      const zoom = getZoom();
      const delta = state.direction[1] * getVelocity(state.velocity[1], 20); // 1 for up, -1 for down
      const newZoom = zoom + delta *speed;
      if (newZoom < 1) return;

      setZoom(newZoom);

      getMaxPanTilt({
        fov: newZoom,
        maxFov: getMinFov(cameraId),
      });
    },
    { target: ref },
  );

  useDrag(
    (state) => {
      if (!state.active) {
        // ignores the extra event that use-gesture includes to indicate when the gesture ends
        // see https://github.com/pmndrs/use-gesture/issues/202
        return;
      }

      if (cameraId === ALL_CAMERAS_VIDEO_SOURCE) {
        return;
      }

      const x = state.movement[0];
      const y = state.movement[1];

      const tilt = -1 * y;
      const pan = x;

      const mappedPan = mapValueToRange(pan, -dragBoundaries.pan, dragBoundaries.pan, -67.5, 67.5);
      const mappedTilt = mapValueToRange(
        tilt,
        -dragBoundaries.tilt,
        dragBoundaries.tilt,
        -67.5,
        67.5,
      );

      setPtzVelocity({
        id: cameraId,
        pan: toVelocity(mappedPan * -1, 3),
        tilt: toVelocity(mappedTilt * -1, 3),
        zoom: 0,
      });
    },
    { target: ref },
  );

  const stopDrag = (e) => {
    if (cameraId === ALL_CAMERAS_VIDEO_SOURCE) {
      return;
    }

    setPtzVelocity({
      id: cameraId,
      pan: 0,
      tilt: 0,
      zoom: 0,
    });
  };

  return (
    <Box ref={ref} onMouseUp={stopDrag}>
      {children}
    </Box>
  );
};

export default WheelZoom;
