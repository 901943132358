import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import { Tracking } from '@/shared/services/tracking';

import TrendDetails from '../../analytics/TrendDetails';
import { NUMBER_OF_VALUES } from '../constants';
import { calculateBitrate } from '../services/calculateBitrate';

export type WebmetricsParams = {
  toggleStatsVisibility: boolean,
  statsReport: RTCStatsReport
}

const WebrtcMetrics = ({toggleStatsVisibility, statsReport}:WebmetricsParams) => {

  const [jitter, setJitter] = React.useState(0);
  const [fps, setFps] = React.useState(0);
  const [currentBitrate, setBitrate] = React.useState(0);
  const [jitterHistory, setJitterHistory] = React.useState<any[]>([]);
  const [fpsHistory, setFPSHistory] = React.useState<any[]>([]);
  const [bitrateHistory, setBitrateHistory] = React.useState<any[]>([]);

  const [, setJitterBuffer] = React.useState<any[]>([]);
  const [, setFPSBuffer] = React.useState<any[]>([]);
  const [, setBitrateBuffer] = React.useState<any[]>([]);
  const [framesDropped, setFramesDropped] = React.useState(0);


  const updateArray = React.useCallback((data, setArray, maxLength) => {
    setArray((prevArray) => {
      const newArray = [...prevArray, data];
      if (newArray.length > maxLength) {
        newArray.shift();
      }
      return newArray;
    });
  }, []);

  const updateBuffer = React.useCallback((data, setArray, name) => {
    setArray((prevArray) => {
      const newArray = [...prevArray, data];
      if (newArray.length % NUMBER_OF_VALUES === 0) {
        const average = newArray.reduce((acc, val) => acc + val, 0) / newArray.length;

        Tracking.getInstance().track('WEBRTC_METRIC', {
          avg: average,
          name,
        });
      }
      return newArray;
    });
  }, []);

  useEffect(()=>{
    if(!statsReport || !statsReport.forEach) return; 
    statsReport.forEach((report) => {
      if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
        const bitrate = calculateBitrate(report);
        updateArray(
          Number.parseFloat((bitrate / 1000000).toFixed(2)),
          setBitrateHistory,
          120,
        );
        updateBuffer(
          Number.parseFloat((bitrate / 1000000).toFixed(2)),
          setBitrateBuffer,
          'bitrate',
        );

        setBitrate(Number.parseFloat((bitrate / 1000000).toFixed(2)));
      }

      Object.keys(report).forEach((key) => {
        if (key === 'jitter') {
          updateArray(report[key], setJitterHistory, 120);
          updateBuffer(report[key], setJitterBuffer, 'jitter');
          setJitter(report[key]);
        }
        if (key === 'framesPerSecond') {
          updateArray(report[key], setFPSHistory, 120);
          updateBuffer(report[key], setFPSBuffer, 'fps');
          setFps(report[key]);
        }
        if (key === 'framesDropped') {
          setFramesDropped(report[key]);
        }
      });
    });

  },[statsReport])

  return (
         <Box
            visibility={toggleStatsVisibility ? 'visible' : 'hidden'}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              aspectRatio: '16/9',
              display: 'flex',
              direction: 'rtl',
            }}
          >
            <TrendDetails
              style={{ width: 210, height: 140, opacity: 0.75 }}
              title="Jitter"
              data={jitterHistory}
              value={jitter}
              limit={120}
            />
            <TrendDetails
              style={{ width: 210, height: 140, opacity: 0.75 }}
              title="Fps"
              limit={120}
              data={fpsHistory}
              value={fps}
            />
            <TrendDetails
              style={{ width: 210, height: 140, opacity: 0.75 }}
              title="Frames Dropped"
              limit={120}
              value={framesDropped}
            />
            <TrendDetails
              style={{ width: 210, height: 140, opacity: 0.75 }}
              title="Bitrate"
              limit={120}
              data={bitrateHistory}
              value={`${currentBitrate}Mbps`}
            />
          </Box>
          )
};

export default WebrtcMetrics;
