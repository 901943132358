import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

export const getQueryKey = (email: string) => ['userLeague', email];

const useUserLeague = (
  email: string
) => useQuery(
  getQueryKey(email),
  async () => {

    const { data } = await get(`user-league?email=${encodeURIComponent(email)}`);
    return data;
  },
  {
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: false
  },
);

export default useUserLeague;
