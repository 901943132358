import { SetCameraPtzModel } from '../../features/camera/types';
import { PtzVelocityMessage } from '../types';
import useWebSocket from './useWebSocket';

const usePtzVelocity = () => {
  const socket = useWebSocket<PtzVelocityMessage>('/ptz/velocity');

  return {
    setPtzVelocity: (velocities: SetCameraPtzModel) => socket.send({
      cameraId: velocities.id,
      pan: velocities.pan,
      tilt: velocities.tilt,
      zoom: velocities.zoom,
    }),
  };
};

export default usePtzVelocity;
