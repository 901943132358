import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import CosmLogo from '@/assets/cosm_logo.png';

type Props = {
  leagueLogo: string;
  height: number;
  justifyContent?: string;
};

export default function AnimatedCosmLogo({ leagueLogo, height, justifyContent }: Props) {
  const logoControls = useAnimation();
  const separatorControls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      if (leagueLogo) {
        // Start by animating the logos
        await logoControls.start({
          opacity: 1,
          x: 0,
          transition: { duration: 0.2 },
        });
        // Then animate the separator
        await separatorControls.start({
          opacity: 1,
          transition: { duration: 0.2 },
        });

      } else {
        try{
            // If leagueLogo is not present, animate the CosmLogo back to its original position
            await logoControls.start({
              x: 0,
              transition: { duration: 1 },
            });
            // And hide the separator
            await separatorControls.start({
              opacity: 0,
              transition: { duration: 1 },
            });
            
        }catch(e){
          // noop
        }
 
      }
    };
    sequence();
  }, [leagueLogo]);

  return (
    <Stack direction="row" justifyContent={justifyContent} alignItems="center" sx={{ margin: 2 }}>
      <motion.img
        src={CosmLogo}
        alt="Cosm Logo"
        style={{ height }}
        animate={{ x: leagueLogo ? -10 : 0 }}
        transition={{ duration: 0.2 }}
      />

      {leagueLogo && (
        <>
          <motion.div
            style={{
              height: height - 10,
              borderLeft: '1px solid lightgray',
              opacity: 0,
            }}
            initial={{ opacity: 0 }}
            animate={separatorControls}
          />

          <motion.img
            src={leagueLogo}
            alt="League Logo"
            style={{
              height,
              paddingLeft: 10,
            }}
            initial={{ opacity: 0, x: -50 }}
            animate={logoControls}
          />
        </>
      )}
    </Stack>
  );
}

AnimatedCosmLogo.defaultProps = {
  justifyContent: 'center',
};
