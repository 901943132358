import { differenceInSeconds, format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Chip,
  Collapse,
  CollapseProps,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { grey } from '@mui/material/colors';
import { Videocam } from '@mui/icons-material';

import useCognito from '../../authentication/hooks/useCognito';
import { PANEL_PADDING } from '../../games/constants';
import { ClipModel, ClipType } from '../types';
import ClipRecordContextMenu from './ClipRecordContextMenu';
import ClipRecordForm from './ClipRecordForm';

import DisplayTags from '@/features/tags/components/DisplayTags';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { NO_TRACKING_ID } from '@/shared/hooks/useServerState';
import displayWallClock from '@/shared/services/displayWallClock';
import ExpandToggleButton from '@/shared/components/ExpandToggleButton';
import useAutoIsoEntitiesQuery from '@/features/autoIso/hooks/useAutoIsoEntitiesQuery';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';

import notFoundLogo from '@/assets/not-found.jpg';
import ClipStatus from '@/shared/components/ClipStatus';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';

type Props = {
  clip: ClipModel;
  isFirst: boolean;
  teams?: unknown;
  collapseProps?: CollapseProps;
  downloadCliphandler: (clipId: number) => void;
};

const getDuration = (start: Date, end: Date) => {
  try {
    const d = differenceInSeconds(end, start);
    if (Number.isNaN(d)) return '';
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s
      .toString()
      .padStart(2, '0')}`;
  } catch (e) {
    console.error(e);
    return '';
  }
};

const ClipRecord = ({ clip, isFirst, teams, collapseProps = {}, downloadCliphandler }: Props) => {
  const { state, handleSubmit, handleCancelEditing, handleSetClipRecordRef, handleView } =
    useClipStateContext();
  const serverState = useServerStateContext();

  const cameras = Object.values(serverState.cameraSettingsState);

  const gameTypeMetadata = useGameTypeMetadataQuery();

  const clipRecordRef = useRef<HTMLElement>(null);
  const cognito = useCognito();
  const autoIsoQuery = useAutoIsoEntitiesQuery();
  const autoIsoEntities = autoIsoQuery.data?.list;
  const [expanded, setExpanded] = useState(false);
  const editing = state.editingModel?.id === clip.id;
  const isEditingSomeOtherClip = state.isEditing && !editing;
  const isOwnClip = clip.clouduser?.email === cognito.userAttributes?.email;
  const trackedObject = autoIsoEntities?.find((entity) => entity?.id === clip.objectTrackingId);
  const [displayDescription, setDisplayDescription] = useState(!!clip.description && !editing);
  const [clipDescription, setClipDescription] = useState(clip.description);

  // eslint-disable-next-line no-nested-ternary
  const trackedEntity = clip?.objectTrackingId
    ? clip?.objectTrackingId === '1'
      ? gameTypeMetadata.data?.objectOfPlay
      : `${trackedObject?.playerNumber} - ${trackedObject?.displayName} (${trackedObject?.playerPosition})`
    : '-';
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    handleSetClipRecordRef(clip.id, clipRecordRef);
  }, [clip.id, handleSetClipRecordRef]);

  const noteStyle = {
    textTransform: 'capitalize',
  };

  const handleEditSubmit = async (values: ClipModel) => {
    await handleSubmit(values);
    setClipDescription(values.description);
    setDisplayDescription(values.description.length > 0);
  };

  const getTeamLogo = React.useCallback(() => {
    if (trackedObject.playerTeamId && teams?.data) {
      const team = teams?.data.find((t) => t.abbreviation === trackedObject.playerTeamId);
      if (team) return useTeamLogoPath(team);
    }
    return notFoundLogo;
  }, [trackedObject, teams]);

  const getCameraNameByCameraId = React.useCallback(
    (cameraId: string) => {
      const camera = cameras.find((c) => c.id === cameraId);
      if (camera) return camera.name;
      return 'Multi Camera';
    },
    [cameras],
  );

  return (
    <Stack>
      {!isEditingSomeOtherClip && (
        <Stack
          ref={clipRecordRef as React.RefObject<HTMLDivElement>}
          sx={(theme) => ({
            cursor: 'pointer',
            backgroundColor: editing ? theme.palette.background.default : undefined,
            opacity: isEditingSomeOtherClip ? 0.5 : 0.8,
            '&:hover': {
              opacity: 1,
            },
          })}
          onMouseEnter={() => setMenuVisible(true)}
          onMouseLeave={() => setMenuVisible(false)}
        >
          {!isFirst && <Divider />}

          <Stack ml={PANEL_PADDING - 1} mr={1.5}>
            <Stack direction="column" mt={1}>
              <Stack direction="row-reverse" alignItems="center" sx={{ flex: 1, marginBottom: 1 }}>
                {/* context menu */}

                <ClipRecordContextMenu
                  visible={menuVisible}
                  clip={clip}
                  exportClickHandler={() => setExpanded(true)}
                  downloadCliphandler={() => downloadCliphandler(clip.id)}
                />
                <Box p={1}>
                  {clip.type === ClipType.Clip && <ClipStatus status={clip.status} />}
                </Box>

                {/* clip duration OR bookmark timestamp */}
                <>
                  {clip.type === ClipType.Clip ? (
                    <Tooltip
                      title={`${displayWallClock(clip.startTimestamp)} - ${displayWallClock(
                        clip.endTimestamp,
                      )}`}
                      placement="left-start"
                    >
                      <Chip
                        label={getDuration(clip.startTimestamp, clip.endTimestamp)}
                        variant="outlined"
                        size="small"
                        color="success"
                      />
                    </Tooltip>
                  ) : (
                    <Chip
                      label={displayWallClock(clip.bookmarkTimestamp)}
                      variant="outlined"
                      size="small"
                      color="info"
                    />
                  )}
                </>
                {/* clip note */}
                <Box
                  sx={{
                    paddingTop: 0.5,
                    overflowWrap: 'break-word',
                    width: '200px',
                  }}
                  flexGrow={1}
                  onClick={() => handleView(clip)}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    style={noteStyle}
                    sx={{ flex: 1 }}
                  >
                    {clip.note}
                  </Typography>
                </Box>

                {/* expand button */}
                <Box sx={{ paddingTop: 0.1 }}>
                  <ExpandToggleButton
                    expanded={expanded}
                    onClickHandler={() => setExpanded(!expanded)}
                    disabled={isEditingSomeOtherClip}
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Collapse
            sx={{
              flexShrink: 0,
              backgroundColor: grey[800],
              ...(collapseProps.sx || {}),
            }}
            in={expanded && !isEditingSomeOtherClip}
          >
            {clip.type === ClipType.Clip && (
              <Box p={2}>
                <Box
                  display="flex"
                  sx={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}
                >
                  <Chip
                    size="small"
                    icon={<Videocam />}
                    label={getCameraNameByCameraId(clip.cameraId)}
                  />

                  {!!clip.objectTrackingId && trackedObject?.type === 'Player' && (
                    <Chip
                      avatar={<Avatar alt="TrackedObject" src={getTeamLogo()} />}
                      label={trackedEntity}
                      variant="outlined"
                    />
                  )}

                  {!!clip.objectTrackingId && trackedObject?.type === 'BallOrPuck' && (
                    <Chip label={trackedEntity} variant="outlined" />
                  )}
                </Box>

                {(!clip.objectTrackingId || clip.objectTrackingId === NO_TRACKING_ID) &&
                  clip.pan && (
                    <Typography variant="subtitle2">
                      PTZ: {clip.pan} / {clip.tilt} / {clip.zoom}
                    </Typography>
                  )}

                {displayDescription && (
                  <Box
                    sx={{
                      overflowWrap: 'break-word',
                      width: '100%',
                      paddingBottom: 0,
                      paddingTop: 0,
                      paddingRight: 2,
                      paddingLeft: 2,
                    }}
                  >
                    <Typography variant="subtitle2">{clipDescription}</Typography>
                  </Box>
                )}
              </Box>
            )}

            {clip.type === ClipType.Bookmark && displayDescription && (
              <Box
                p={2}
                sx={{
                  overflowWrap: 'break-word',
                  width: '100%',
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 2,
                }}
              >
                <Typography variant="subtitle2">{clipDescription}</Typography>
              </Box>
            )}
            <Box p={2}>
              <Typography variant="caption" color={grey[500]}>
                {clip.clouduser.email.toLowerCase()} &#183;&nbsp;
                {format(clip.createdAt, 'PP')}
              </Typography>
            </Box>
          </Collapse>
          <Stack>
            {editing && (
              <Box p={2}>
                <ClipRecordForm
                  clip={clip}
                  onCancel={handleCancelEditing}
                  onSubmit={handleEditSubmit}
                />
              </Box>
            )}
            {!editing && (
              <Box px={1.6}>
                <DisplayTags clip={clip} readonly={!isOwnClip || isEditingSomeOtherClip} />
              </Box>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

ClipRecord.defaultProps = {
  teams: {},
  collapseProps: { sx: {} },
};
export default ClipRecord;
