import * as React from 'react';

import FitScreenRoundedIcon from '@mui/icons-material/FitScreenRounded';
import VideocamTwoToneIcon from '@mui/icons-material/VideocamTwoTone';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useServerStateContext from '@/shared/hooks/useServerStateContext';

import { ALL_CAMERAS_VIDEO_SOURCE } from '../../camera/types';

const PlayerCameraFeedSelectionButton = () => {
  const { cameraSettingsState, setVideoSourceCameraId, videoSourceCameraId } = useServerStateContext();
  const singleCameraSelected = videoSourceCameraId !== ALL_CAMERAS_VIDEO_SOURCE;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        aria-controls={open ? 'camera-feed-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{ color: singleCameraSelected ? 'text.primary' : 'text.secondary' }}
      >
        <FitScreenRoundedIcon />
      </IconButton>
      <Menu
        disablePortal
        anchorEl={anchorEl}
        id="camera-feed-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 24,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={(theme) => ({
          // Make sure the menu shows above the tooltip for the button
          zIndex: theme.zIndex.tooltip + 1,
        })}
      >
        {Object.values(cameraSettingsState)
          .filter((c) => c.id)
          .map((camera) => (
            <MenuItem
              key={camera.id}
              onClick={() => setVideoSourceCameraId(camera, 'Video Player Toolbar')}
            >
              <ListItemIcon>
                <VideocamTwoToneIcon
                  fontSize="small"
                  color={videoSourceCameraId === camera.id ? 'primary' : undefined}
                  style={{
                    transform: `rotate(${camera.angle}deg)`,
                  }}
                />
              </ListItemIcon>
              {camera.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default PlayerCameraFeedSelectionButton;
