import { useRef } from 'react';

import { PlaybackSpeedMessage } from '../types';
import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from './withStateFromRef';

export const usePlaybackSpeedRef: WebSocketRefHook<number> = (options = {}) => {
  const ref = useRef(0);

  const handleChange = (value: number) => {
    ref.current = value;

    if (options.onChange) {
      options.onChange(value);
    }
  };

  const socket = useWebSocket<PlaybackSpeedMessage>('/playback-speed', {
    onMessage: (message) => {
      handleChange(message.playbackSpeed);
    },
  });

  const setValue = (value: number) => {
    socket.send({ playbackSpeed: value });
    handleChange(value);
  };

  return [ref, setValue];
};

export const usePlaybackSpeedState = withStateFromRef(usePlaybackSpeedRef);
