import React from 'react';
import { Joystick, JoystickShape } from 'react-joystick-component';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import JOYSTICK_TOP from '@/assets/joystick-top.svg';

type Props = {
  disabled: boolean;
  onChange: (pan: number, tilt: number) => unknown;
};

const MAX_VELOCITY = 3; // degrees/frame
const SIZE = 135;
const MAX_DRAG_DISTANCE = SIZE * 2;
const THROTTLE = (1 / 60) * 1000; // 60fps in milliseconds

const toVelocity = (dragDistance: number) => (dragDistance / MAX_DRAG_DISTANCE) * MAX_VELOCITY;

const PtzJoystick = ({ disabled, onChange }: Props) => {

  const handleMove = (e: IJoystickUpdateEvent) => {
    const pan = toVelocity(e.x);
    const tilt = toVelocity(e.y);
    onChange(pan, tilt);
  };

  const handleStop = (_e: IJoystickUpdateEvent) => {
    // noop
    onChange(0, 0);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: `1fr ${SIZE}px 1fr`,
        gridTemplateColumns: `1fr ${SIZE}px 1fr`,
        justifyItems: 'center',
        alignItems: 'center',
        my: 2,
      }}
    >
      <Typography variant="subtitle2" sx={{ gridColumnStart: 2, m: 1 }}>
        Tilt Up (w)
      </Typography>

      <Typography variant="subtitle2" sx={{ gridColumnStart: 3, gridRowStart: 2, m: 1 }}>
        Pan Right (d)
      </Typography>

      <Typography variant="subtitle2" sx={{ gridColumnStart: 2, gridRowStart: 3, m: 1 }}>
        Tilt Down (s)
      </Typography>

      <Typography variant="subtitle2" sx={{ gridRowStart: 2, m: 1 }}>
        Pan Left (a)
      </Typography>

      <Box
        sx={{
          boxShadow: 20,
          display: 'inline-block',
          borderRadius: '20px',
          overflow: 'hidden',
          gridColumnStart: 2,
          gridRowStart: 2,
          '& button': {
            boxShadow: 24,
          },
        }}
      >
        <Joystick
          baseColor="linear-gradient(-180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 99%) rgb(18, 18, 18)"
          baseShape={JoystickShape.Square}
          disabled={disabled}
          move={handleMove}
          size={SIZE}
          stickColor="rgb(124, 179, 66)"
          stickImage={JOYSTICK_TOP}
          stop={handleStop}
          throttle={THROTTLE}
        />
      </Box>
    </Box>
  );
};

export default PtzJoystick;
