import './styles/styles.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/react';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { sentryDSN, enableSentry } from '@/shared/constants';

import { xsrfTokenCheck } from './features/scaffolding/services';
import Loading from './Loading';
// Root and Loading need to stay out here for HMR purposes
import Root from './Root';
import { launchDarkly } from './shared/constants';
import { configureHttp, configureStore } from './util';

// setup
const appElement = document.getElementById('app');
const root = createRoot(appElement);
const store = configureStore();

configureHttp({});

if (enableSentry) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.05, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else {
  console.debug('Sentry is disabled, skipping tracing and session replay');
}

// render paths
const renderApplication = () => {
  (async () => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: launchDarkly,
    });

    root.render(
      <LDProvider>
        <React.StrictMode>
          <Root store={store} />
        </React.StrictMode>
      </LDProvider>,
    );
  })();
};

const renderLoading = () => {
  root.render(<Loading onRender={renderApplication} />);
};

// boot
if (xsrfTokenCheck()) {
  renderApplication();
} else {
  renderLoading();
}
