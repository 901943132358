import { useQuery } from '@tanstack/react-query';
import { post } from '@truefit/http-utils';

const useITMSession = () => useQuery(
  ['SessionCreation'],
  async (): Promise<any> => {
    await post<void>('session', {});
    return {};
  },
  {
    enabled: false,
  },
);

export default useITMSession;
