import { parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { GameModelFromServer } from '../types';

import { UserStateContext } from '@/shared/components/UserStateProvider';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';
import { formatStart } from '@/shared/services/formatStart';
import { getDuration } from '@/shared/services/getDuration';
import { Tracking } from '@/shared/services/tracking';

type Props = {
  game: GameModelFromServer;
};

const LiveIndicator = ({ color }: { color: string }) => (
  <Box
    sx={{
      paddingLeft: '13px',
      paddingRight: '13px',
      color,
    }}
    justifyContent="right"
  >
    <div style={{ overflow: 'hidden', width: 30, height: 30 }}>
      <Typography
        variant="subtitle1"
        sx={{ margin: 0, padding: 0, lineHeight: 1.25, textTransform: 'capitalize' }}
      >
        Live
      </Typography>
      <motion.div
        style={{ backgroundColor: '#DB2B39', width: 20, height: 4 }}
        animate={{ x: ['-25px', '35px', '-25px'] }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      />
    </div>
  </Box>
);

const GameCard = ({ game }: Props) => {
  const start = parseISO(game.startTimestamp);
  const end = parseISO(game.endTimestamp);
  const userContext = React.useContext(UserStateContext);

  const [isLive, setIsLive] = useState(game.status === 'live');
  const [isHovered, setIsHovered] = useState(false);
  const [opacity, setOpacity] = useState(game.status === 'upcoming' ? 0.4 : 1);
  const [gameStatus, setGameStatus] = useState(game.status);

  const [formattedStartDate, setFormattedStartDate] = useState<string | null>(
    formatStart(start, userContext?.user.tz),
  );
  
  const updateGameStatus = async () => {
    if (Number.isNaN(game.id)) return null;
    const { data } = await get<GameModelFromServer>(`games/${game.id}?polling=true`);
    setGameStatus(data.status);
    setIsLive(data.status === 'live');
    setOpacity(data.status === 'upcoming' ? 0.7 : 1);
    return data.status;
  };
  
  const queryKey = (gameId: number) => ['GamesStatus', gameId];
  
  useEffect(() => {
    setFormattedStartDate(formatStart(start, userContext?.user.tz));
  }, [start, userContext?.user.tz]);
  
  useEffect(() => {
    if (game.status === 'upcoming') {
      const interval = setInterval(() => {
        updateGameStatus();
      }, 1000 * 3);
  
      return () => clearInterval(interval);
    }
  }, [game.id, game.status]);
  
  useEffect(() => {
    setOpacity(game.status === 'upcoming' ? 0.7 : 1);
  }, [game.status]);
  
  useQuery(
    queryKey(game.id),
    async () => updateGameStatus(),
    {
      enabled: game.status === 'upcoming',
      refetchInterval: 1000 * 3,
    },
  );
  
  



  return (
    <Card
      sx={() => ({
        display: 'flex',
        flexDirection: 'column',
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ opacity: isHovered ? 1 : opacity, transition: 'opacity 0.3s'}}
    >
      <CardContent sx={{ flexGrow: 1, paddingBottom: 0, backgroundColor: '#323239' }}>
        <Box sx={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box>{formattedStartDate}</Box>
            <Box>{isLive ? <LiveIndicator color="white" /> : getDuration(start, end)}</Box>
          </Box>

          <Box
            sx={{
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={useTeamLogoPath(game.awayTeam)}
                width={45}
                style={{ marginRight: 7 }}
                alt={game.awayTeam.abbreviation}
              />
              <Typography variant="h5"> {game.awayTeam.abbreviation}</Typography>
              <Typography variant="h6" marginLeft={2} sx={{ opacity: 0.6 }}>
                {' '}
                {game.awayTeam.name}
              </Typography>
              <Box flexGrow="1">
                {game.externalid && gameStatus !== 'upcoming' && (
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: 'right',
                      paddingRight: 5,
                      fontWeight: 'bold',
                      opacity: game.awayTeamScore >= game.homeTeamScore ? 1 : 0.4,
                    }}
                  >
                    {game.awayTeamScore}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
              <img
                src={useTeamLogoPath(game.homeTeam)}
                width={45}
                style={{ marginRight: 7 }}
                alt={game.homeTeam.abbreviation}
              />
              <Typography variant="h5"> {game.homeTeam.abbreviation}</Typography>
              <Typography variant="h6" marginLeft={2} sx={{ opacity: 0.6 }}>
                {' '}
                {game.homeTeam.name}
              </Typography>
              <Box flexGrow="1">
                {game.externalid && gameStatus !== 'upcoming' && (
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: 5,
                      opacity: game.homeTeamScore >= game.awayTeamScore ? 1 : 0.4,
                    }}
                  >
                    {game.homeTeamScore}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>

      <CardActions disableSpacing sx={{ backgroundColor: '#2c2c34' }}>
        <Button
          component={Link}
          to={`/games/${game.id}`}
          sx={(theme) => ({
            textTransform: 'uppercase',
            letterSpacing: 1,
            margin: 0,
            color: theme.palette.secondary.main,
          })}
          disabled={!['live', 'recorded'].includes(gameStatus)}
          onClick={() => {
            Tracking.getInstance().track('Click Watch Game', {
              category: 'Access & Navigation',
              label: 'Button',
              isLive,
              game: game.id,
            });
          }}
        >
          {isLive ? 'Join Game' : 'Watch Game'}
        </Button>
      </CardActions>
    </Card>
  );
};

export default GameCard;
