import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';

import {
  KeyboardShortcut,
  KeyboardShortcutGroup,
  KeyboardShortcutGroupOrderNumber,
  KeyboardShortcutGroupTitles,
  KeyboardShortcutsList,
} from '../constants';
import useKeyboardShortcutsDialog from '../hooks/useKeyboardShortcutsDialog';
import KeyboardCmd from './KeyboardCmd';
import { MouseDiagram } from '@/features/games/components';

type Props = {
  shortcuts?: Record<KeyboardShortcutGroup, KeyboardShortcut[]>;
};

const KeyboardShortcutsDialog = ({ shortcuts = KeyboardShortcutsList }: Props) => {
  const { showing, hide } = useKeyboardShortcutsDialog();

  return (
    <Dialog open={showing} onClose={hide} maxWidth={false} disablePortal>
      <DialogTitle>Keyboard Shortcuts</DialogTitle>

      <DialogContent>
        <Stack direction="row" spacing={8} sx={{ mt: 2 }}>
          {(Object.keys(shortcuts) as KeyboardShortcutGroup[])
            .sort(
              (a, b) => KeyboardShortcutGroupOrderNumber[a] - KeyboardShortcutGroupOrderNumber[b],
            )
            .map((group) => (
              <Box key={group}>
                <Typography variant="subtitle2">{KeyboardShortcutGroupTitles[group]}</Typography>
                {shortcuts[group].map((s) => (
                  <Stack key={s.command} direction="row" spacing={1} sx={{ mt: 1 }}>
                    <KeyboardCmd keys={s.keys} />
                    <Typography variant="overline" sx={{ ml: 1, whiteSpace: 'nowrap' }}>
                      {s.command}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            ))}
          <Box sx={{ width: '225px', m: 2, mt: 4 }}>
            <Typography variant="subtitle2">Mouse Controls</Typography>

            <MouseDiagram />
          </Box>
        </Stack>
      </DialogContent>

      <Divider sx={{ mx: 3 }} />

      <DialogActions>
        <Button onClick={hide} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

KeyboardShortcutsDialog.defaultProps = {
  shortcuts: KeyboardShortcutsList,
};

export default KeyboardShortcutsDialog;
