/* eslint @typescript-eslint/no-unused-vars: "off" */

import { useEffect, useRef, useState } from 'react';

import { Tracking } from '@/shared/services/tracking';

declare global {
  interface Document {
    webkitExitFullscreen?: () => Promise<void>;
    webkitFullscreenElement?: Element;
  }

  interface HTMLDivElement {
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

const useFullscreen = () => {
  const ref = useRef<HTMLDivElement>();
  const [isFullscreen, setIsFullscreen] = useState(false);


  const handleFullscreenChange = () => {
    const el = ref.current;

    const isfullScreen = window.document.fullscreenElement === el || window.document.webkitFullscreenElement === el;

    setIsFullscreen(isfullScreen);

    Tracking.getInstance().track('Switch View', {
      source: 'Video Player Toolbar',
      category: 'Controls',
      type: 'fullscreen',
      fullscreenStatus: isfullScreen ? 'on' : 'off',
    });
  }

  // tracks the fullscreen state
  useEffect(() => {
    const el = ref.current;

    if (!el) {
      return undefined;
    }

    el.addEventListener('fullscreenchange', handleFullscreenChange);
    el.addEventListener('webkitfullscreenchange', handleFullscreenChange);

    return function cleanup() {
      el.removeEventListener('fullscreenchange', handleFullscreenChange);
      el.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
    };
  }, [ref.current]);

  const requestFullscreen = async () => {
    const el = ref.current;

    if (el) {
      if (el.requestFullscreen) {
        await el.requestFullscreen();
      } else if (el.webkitRequestFullscreen) {
        await el.webkitRequestFullscreen();
      }
    }
  };

  const exitFullscreen = async () => {
    if (window.document.exitFullscreen) {
      await window.document.exitFullscreen();
    } else if (window.document.webkitExitFullscreen) {
      await window.document.webkitExitFullscreen();
    }
  };

  return {
    exitFullscreen, isFullscreen, ref, requestFullscreen,
  };
};

export default useFullscreen;
