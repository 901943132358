import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

export type User = {
  tz?: string,
  email?: string,
  lastseen?: string
};

const useCurrentUser = () => useQuery(
  ['user'],
  () => get<User>('user'),
  {
    enabled: false, // I will trigger this manually
    retry: true,
  },
);

export default useCurrentUser;
