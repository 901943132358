import { isValid } from 'date-fns';

import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

import { FormValues as ClipsFormValues } from '../components/ClipsForm';
// import toClipModel from '../services/toClipModel';
import { ClipModelFromServer } from '../types';

export const getQueryKey = (formValues: ClipsFormValues) => ['AllClips', { formValues }];
type GetClipsResult = {
  results: ClipModelFromServer[];
  offset: number;
  total: number;
};

type QueryParams = ClipsFormValues & {
  textSearch: string;
  limit: number;
  offset: number;
};

export const fetchClips = ({
  date,
  team,
  limit,
  offset,
  sort,
  clipType,
  tags,
  textSearch,
}: QueryParams) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('limit', limit.toString());
  urlSearchParams.set('offset', offset.toString());
  urlSearchParams.set('sort', sort);

  if (tags && Array.isArray(tags)) {
    const mappedTags = tags.map((t) => t.name);
    if (Array.isArray(mappedTags) && mappedTags.length > 0) {
      urlSearchParams.set('tags', tags.join('|'));
    }
  }

  if (team) {
    urlSearchParams.set('team', team);
  }
  if (textSearch) {
    urlSearchParams.set('text', textSearch);
  }

  if (date && isValid(date)) {
    urlSearchParams.set('date', date.toISOString());
  }

  urlSearchParams.set('clipType', clipType);

  return get<GetClipsResult>(`clips?${urlSearchParams.toString()}`);
};

const useAllClipsQuery = (
  formValues: ClipsFormValues,
  textSearch: string,
  pageSize: number,
  page: number,
) => useQuery(
  getQueryKey(formValues),
  async () => {
    const { data } = await fetchClips({
      offset: page * pageSize,
      limit: pageSize,
      textSearch,
      ...formValues,
    });
    return data;
  },
  {
    cacheTime: 0,
    staleTime: Infinity,
    refetchInterval: 5000,
  },
);

export default useAllClipsQuery;
