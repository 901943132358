import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import toClipModel from '../services/toClipModel';
import { ClipModelFromServer } from '../types';

export const getQueryKey = (gameId: number) => ['Games', gameId, 'Clips'];

const useCurrentGameClipsQuery = () => {
  const gameId = useCurrentGameId();

  return useQuery(getQueryKey(gameId), async () => {
    const { data } = await get<ClipModelFromServer[]>(`games/${gameId}/clips`);
    return data.map((c) => toClipModel(c));
  });
};

export default useCurrentGameClipsQuery;
