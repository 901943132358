// prevents the cards from being too long when there aren't enough to fill a whole row
export const getGridMaxWidth = (gamesCount: number) => {
  if (gamesCount === 1) {
    return '33.333%';
  }

  if (gamesCount === 2) {
    return '66.666%';
  }

  return '100%';
};


export default getGridMaxWidth;