import { useSnackbar } from 'notistack';
import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { set, startCase } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import subMilliseconds from 'date-fns/subMilliseconds';
import { Tracking } from '@/shared/services/tracking';

import { ALL_CAMERAS_VIDEO_SOURCE } from '../../features/camera/types';
import useAddClipMutation from '../../features/clips/hooks/useAddClipMutation';
import useDeleteClipMutation from '../../features/clips/hooks/useDeleteClipMutation';
import useUpdateClipMutation from '../../features/clips/hooks/useUpdateClipMutation';
import { toClipWithSyncedTimes } from '../../features/clips/services';
import { AddClipModel, ClipModel, ClipType } from '../../features/clips/types';
import displayWallClock from '../services/displayWallClock';
import { ClipState } from '../types/ClipState';
import useConfirmationDialogContext from './useConfirmationDialogContext';
import useCurrentGameId from './useCurrentGameId';
import { usePanStore } from './usePan';
import { usePlaybackSpeedRef } from './usePlaybackSpeed';
import { ServerStateAndFunctionWithoutGetter } from './useServerState';
import useServerStateContext from './useServerStateContext';
import { useTiltStore } from './useTilt';
import { useTimestampRef } from './useTimestamp';
import { useTimestampEndRef } from './useTimestampEnd';
import { useTimestampStartRef } from './useTimestampStart';
import { useZoomStore } from './useZoom';

const dirtiableBookmarkFields: (keyof ClipModel)[] = [
  'id',
  'note',
  'description',
  'type',
  'bookmarkTimestamp',
];

const dirtiableClipFields: (keyof ClipModel)[] = [
  'id',
  'note',
  'description',
  'type',
  'startTimestamp',
  'endTimestamp',
  'cameraId',
  'objectTrackingId',
  'pan',
  'tilt',
  'zoom',
];

const getCameraAndTrackingDetailsFromServerState = (
  serverState: ServerStateAndFunctionWithoutGetter,
  pan: number,
  tilt: number,
  zoom: number,
): Pick<AddClipModel, 'cameraId' | 'objectTrackingId' | 'pan' | 'tilt' | 'zoom'> => ({
  cameraId: serverState.videoSourceCameraId,
  objectTrackingId: serverState.singleCurrentlyTrackedObjectId,
  pan,
  tilt,
  zoom,
});

const getClipDetailsFromServerState = (
  type: ClipType,
  serverState: ServerStateAndFunctionWithoutGetter,
  playbackPositionTimestamp: Date,
  pan: number,
  tilt: number,
  zoom: number,
): Omit<AddClipModel, 'gameId'> => ({
  type,
  description: '',
  bookmarkTimestamp: playbackPositionTimestamp,
  startTimestamp: playbackPositionTimestamp,
  endTimestamp: playbackPositionTimestamp,
  ...getCameraAndTrackingDetailsFromServerState(serverState, pan, tilt, zoom),
  tags: [],
});

const DEFAULT_TAGS_FILTER: string[] = [];
const DEFAULT_FILTER = 'all';

const useClipState = () => {
  const [filter, setFilter] = React.useState<ClipType | 'all'>(DEFAULT_FILTER);
  const [tagsFilter, setTagsFilter] = React.useState<string[]>(DEFAULT_TAGS_FILTER);
  const gameId = useCurrentGameId();
  const [clipState, setClipState] = React.useState<ClipState>({});
  const totalClipState = React.useMemo(
    () => ({
      ...clipState,
      isEditing: !!clipState.editingModel,
      isEditingClip: clipState.editingModel?.type === ClipType.Clip,
      isEditingBookmark: clipState.editingModel?.type === ClipType.Bookmark,
      filter,
      tagsFilter,
    }),
    [clipState, filter, tagsFilter],
  );

  const clipRecordRefsLookup = React.useRef<Record<number, MutableRefObject<HTMLElement>>>({});

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const setConfirmationDialog = useConfirmationDialogContext();

  const { getServerStateAndFunctions, setTrackingEntityId, setVideoSourceCameraId } =
    useServerStateContext();
  const addClipMutation = useAddClipMutation();
  const updateClipMutation = useUpdateClipMutation();
  const deleteClipMutation = useDeleteClipMutation();

  const [playbackPositionTimestamp, setPlaybackPositionTimestamp] = useTimestampRef();
  const [recordingEndTimestamp] = useTimestampEndRef();
  const [recordingStartTimestamp] = useTimestampStartRef();
  const [, setPlaybackSpeed] = usePlaybackSpeedRef();
  const { getValue: getPan, setValue: setPan } = usePanStore();
  const { getValue: getTilt, setValue: setTilt } = useTiltStore();
  const {getValue: getZoom, setValue : setZoom} = useZoomStore();
  const { liveTimestampOffset, livePlaybackDelay } = useFlags();
  const clipStateRef = React.useRef(totalClipState);

  React.useEffect(() => {
    clipStateRef.current = totalClipState;
  }, [totalClipState]);

  const getState = React.useCallback(() => clipStateRef.current, []);

  const handleScrollToClipRecord = React.useCallback((clipId: number) => {
    setTimeout(() => {
      clipRecordRefsLookup.current[clipId]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 100);
  }, []);

  const handleApplyCameraSettingsToServer = React.useCallback(
    (model: ClipModel) => {
      if (model.type === ClipType.Clip) {
        setVideoSourceCameraId(model.cameraId);
        if (model.objectTrackingId) {
          setTrackingEntityId(model.objectTrackingId);
        } else if (model.cameraId && model.cameraId !== ALL_CAMERAS_VIDEO_SOURCE) {
          console.warn('Tracking is manual for this clip ... ');
          setPan(model.pan);
          setTilt(model.tilt);
          setZoom(model.zoom);
        }
      }
    },
    [setPan, setTilt, setTrackingEntityId, setVideoSourceCameraId, setZoom],
  );

  const handleView = React.useCallback(
    (modelToView: ClipModel) => {
      if (modelToView.type === ClipType.Clip) {
        // this will set the camera and the tracked object
        handleApplyCameraSettingsToServer(modelToView);
      }
      console.debug('Pausing playback...');
      setPlaybackSpeed(0);
      console.debug(`Adjusting start timestamp to t-${liveTimestampOffset}ms...`);

      // apply liveTimestampOffset when playing the clip
      // Laurent's recommendation
      const position = subMilliseconds(modelToView.startTimestamp, liveTimestampOffset);
      console.debug(`Original clip position ${modelToView.startTimestamp}`);
      console.debug(`Adjusted clip position ${position}`);
      setPlaybackPositionTimestamp(position);

      setTimeout(() => {
        console.debug('Starting playback...');
       // setPlaybackSpeed(modelToView.type === ClipType.Bookmark ? 0 : 1);
         setPlaybackSpeed(1);
      }, livePlaybackDelay);

      Tracking.getInstance().track('View in Timeline', {
        category: 'Bookmarks/Clips',
        type: modelToView.type,
      });
    },
    [handleApplyCameraSettingsToServer, setPlaybackPositionTimestamp, setPlaybackSpeed],
  );

  const handleStartEditing = React.useCallback(
    (modelToEdit: ClipModel) => {
      if (modelToEdit.type === ClipType.Clip) {
        handleApplyCameraSettingsToServer(modelToEdit);
      }
      setPlaybackSpeed(0);
      setPlaybackPositionTimestamp(modelToEdit.startTimestamp);

      setClipState((state: ClipState) => ({
        ...state,
        editingModel: modelToEdit,
        originalModel: modelToEdit,
      }));

      setFilter(DEFAULT_FILTER);
      setTagsFilter(DEFAULT_TAGS_FILTER);

      handleScrollToClipRecord(modelToEdit.id);
    },
    [
      handleApplyCameraSettingsToServer,
      handleScrollToClipRecord,
      setPlaybackPositionTimestamp,
      setPlaybackSpeed,
    ],
  );

  const handleAdd = React.useCallback(
    async (type: ClipType) => {
      if (getState().isEditing) return;

      const serverState = getServerStateAndFunctions();
      const zoom = getZoom();
      const pan = getPan();
      const tilt = getTilt();

      const newClip = toClipWithSyncedTimes(
        getClipDetailsFromServerState(
          type,
          serverState,
          playbackPositionTimestamp.current,
          pan,
          tilt,
          zoom,
        ),
        recordingStartTimestamp.current,
        recordingEndTimestamp.current,
        playbackPositionTimestamp.current,
      );

      const savedClip = await addClipMutation.mutateAsync({ ...newClip, gameId });

      savedClip.note = `${startCase(savedClip.type.toString())} ${savedClip.id}`;

      handleStartEditing(savedClip);

      Tracking.getInstance().track('Add Clip/Bookmark', {
        category: 'Bookmarks/Clips',
        type,
      });
    },
    [
      addClipMutation,
      clipState.editingModel?.id,
      closeSnackbar,
      enqueueSnackbar,
      gameId,
      getServerStateAndFunctions,
      getState,
      handleStartEditing,
      getPan,
      playbackPositionTimestamp,
      recordingEndTimestamp,
      recordingStartTimestamp,
      getTilt,
      getZoom,
    ],
  );

  const handleAddBookmark = React.useCallback(() => {
    handleAdd(ClipType.Bookmark);
  }, [handleAdd]);

  const handleAddClip = React.useCallback(() => {
    handleAdd(ClipType.Clip);
  }, [handleAdd]);

  const handleReset = React.useCallback(
    () =>
      setClipState((state: ClipState) => ({
        ...state,
        editingModel: null,
        originalModel: null,
      })),
    [],
  );

  const getSyncedModel = React.useCallback(
    (newModel: ClipModel): ClipModel => {
      const serverState = getServerStateAndFunctions();
      const zoom = getZoom();
      const pan = getPan();
      const tilt = getTilt();
      const withSyncedTimes = toClipWithSyncedTimes(
        newModel,
        recordingStartTimestamp.current,
        recordingEndTimestamp.current,
        playbackPositionTimestamp.current,
      );
      const withUpdatedCameraAndTracking = {
        ...withSyncedTimes,
        ...getCameraAndTrackingDetailsFromServerState(serverState, pan, tilt, zoom),
      };
      return withUpdatedCameraAndTracking;
    },
    [
      getServerStateAndFunctions,
      getPan,
      playbackPositionTimestamp,
      recordingEndTimestamp,
      recordingStartTimestamp,
      getTilt,
      getZoom,
    ],
  );

  const handleSubmit = React.useCallback(
    async (newModel: ClipModel) => {
      const syncedModel = getSyncedModel(newModel);
      await updateClipMutation.mutateAsync(syncedModel);
      handleReset();
      const { type } = syncedModel;
      enqueueSnackbar(
        `Your edits to ${syncedModel.note} at ${displayWallClock(
          syncedModel.startTimestamp,
        )} have been saved.`,
        {
          action: (id) => (
            <IconButton size="small" onClick={() => closeSnackbar(id)}>
              <CloseIcon sx={{ color: 'black' }} />
            </IconButton>
          ),
        },
      );

      Tracking.getInstance().track('Edit Clip/Bookmark', {
        category: 'Bookmarks/Clips',
        type,
      });
    },
    [closeSnackbar, enqueueSnackbar, getSyncedModel, handleReset, updateClipMutation],
  );


  const handleSetStartTimeOnSlider = React.useCallback(
    (startTimestamp: Date)=>{
      if (getState().editingModel?.type !== ClipType.Clip) return;
      setClipState((state) => ({
        ...state,
        editingModel: {
          ...state.editingModel,
          startTimestamp
        }}))
    }
  ,[getState]);

  const handleSetEndTimeOnSlider = React.useCallback(
    (endTimestamp: Date)=>{
      if (getState().editingModel?.type !== ClipType.Clip) return;
      setClipState((state) => ({
        ...state,
        editingModel: {
          ...state.editingModel,
          endTimestamp
        }}))
    }
  ,[getState]);

  const handleSetStartTime = React.useCallback(() => {
    if (getState().editingModel?.type !== ClipType.Clip) return;
    setClipState((state) => ({
      ...state,
      editingModel: {
        ...state.editingModel,
        startTimestamp:
          playbackPositionTimestamp.current > state.editingModel.endTimestamp
            ? state.editingModel.endTimestamp
            : playbackPositionTimestamp.current,
        endTimestamp:
          playbackPositionTimestamp.current > state.editingModel.endTimestamp
            ? playbackPositionTimestamp.current
            : state.editingModel.endTimestamp,
      },
    }));
  }, [getState, playbackPositionTimestamp]);

  const handleSetEndTime = React.useCallback(() => {
    if (getState().editingModel?.type !== ClipType.Clip) return;
    setClipState((state) => ({
      ...state,
      editingModel: {
        ...state.editingModel,
        startTimestamp:
          playbackPositionTimestamp.current < state.editingModel.startTimestamp
            ? playbackPositionTimestamp.current
            : state.editingModel.startTimestamp,
        endTimestamp:
          playbackPositionTimestamp.current < state.editingModel.startTimestamp
            ? state.editingModel.startTimestamp
            : playbackPositionTimestamp.current,
      },
    }));
  }, [getState, playbackPositionTimestamp]);

  const handleSetBookmarkTime = React.useCallback(() => {
    if (getState().editingModel?.type !== ClipType.Bookmark) return;
    setClipState((state) => ({
      ...state,
      editingModel: {
        ...state.editingModel,
        bookmarkTimestamp: playbackPositionTimestamp.current,
      },
    }));
  }, [getState, playbackPositionTimestamp]);

  const handleDeleteClip = React.useCallback(async (clipModel) => {
    await deleteClipMutation.mutateAsync(clipModel.id)
    setClipState((state: ClipState) => ({
      ...state,
      editingModel: null,
      originalModel: null,
    }))
  }, [deleteClipMutation]);

  const handleDelete = React.useCallback(
    (clipModel: ClipModel) => {
      setConfirmationDialog({
        confirmButtonStyle: 'error',
        title: `Delete ${clipModel.type === ClipType.Clip ? 'Clip' : 'Bookmark'}`,
        message: (
          <Typography>
            This action cannot be undone.
            {clipModel.type === ClipType.Clip ? ' Clip ' : ' Bookmark '}
            <Typography sx={{ fontWeight: '700' }} component="span">
              {clipModel.note}
            </Typography>{' '}
            will be deleted.
          </Typography>
        ),
        confirmText: `Delete ${clipModel.type === ClipType.Clip ? 'Clip' : 'Bookmark'}`,
        cancelText: 'Go Back',
        onConfirm: ()=>handleDeleteClip(clipModel),
        loading: deleteClipMutation.isLoading,
      });
    },
    [deleteClipMutation, setConfirmationDialog],
  );

  const handleConvertToClip = React.useCallback(
    (clipModel: ClipModel) => {
      if (clipModel.type !== ClipType.Bookmark) return;

      setConfirmationDialog({
        title: 'Convert Bookmark to Clip',
        message: (
          <Typography>
            This action cannot be undone. Bookmark{' '}
            <Typography sx={{ fontWeight: '700' }} component="span">
              {clipModel.note}
            </Typography>{' '}
            will be replaced with a new clip.
          </Typography>
        ),
        cancelText: 'Go Back',
        confirmText: 'Convert Bookmark',
        onConfirm: async () => {
          await updateClipMutation.mutateAsync({
            ...clipModel,
            type: ClipType.Clip,
          });

          enqueueSnackbar(
            `${clipModel.note} at ${displayWallClock(
              clipModel.startTimestamp,
            )} has been converted to a clip.`,
            {
              action: (id) => (
                <IconButton size="small" onClick={() => closeSnackbar(id)}>
                  <CloseIcon sx={{ color: 'black' }} />
                </IconButton>
              ),
            },
          );
        },
      });
    },
    [closeSnackbar, enqueueSnackbar, setConfirmationDialog, updateClipMutation],
  );

  const handleCancelEditing = React.useCallback(
    (newModel: ClipModel) => {
      const { editingModel, originalModel } = getState();
      const syncedModel = getSyncedModel(newModel);

      const keys =
        editingModel.type === ClipType.Clip ? dirtiableClipFields : dirtiableBookmarkFields;

      if (
        keys.some(
          (key) =>
            syncedModel[key] !== originalModel[key] &&
            (![undefined, null].includes(syncedModel[key]) ||
              ![undefined, null].includes(originalModel[key])),
        )
      ) {
        setConfirmationDialog({
          title: 'Cancel Changes',
          message: (
            <Typography>
              This action cannot be undone. Your changes to
              {syncedModel.type === ClipType.Clip ? ' clip ' : ' bookmark '}
              <Typography sx={{ fontWeight: '700' }} component="span">
                {syncedModel.note}
              </Typography>{' '}
              will be lost.
            </Typography>
          ),
          onConfirm: handleReset,
          confirmText: 'Cancel Changes',
          cancelText: 'Go Back',
        });
      } else {
        handleReset();
      }
    },
    [getState, getSyncedModel, handleReset, setConfirmationDialog],
  );

  const handleAddTag = React.useCallback(
    async (model: ClipModel, tag: string) => {
      const { editingModel } = getState();

      if (editingModel && editingModel.id === model.id) {
        setClipState((state) => ({
          ...state,
          editingModel: { ...editingModel, tags: [...editingModel.tags, tag] },
        }));
      }

      await updateClipMutation.mutateAsync({ ...model, tags: [...model.tags, tag] });

      enqueueSnackbar(`${tag} tag applied to ${model.note}.`, {
        action: (id) => (
          <IconButton size="small" onClick={() => closeSnackbar(id)}>
            <CloseIcon sx={{ color: 'black' }} />
          </IconButton>
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar, getState, updateClipMutation],
  );

  const handleRemoveTag = React.useCallback(
    async (model: ClipModel, tag: string) => {
      const { editingModel } = getState();
      if (editingModel && editingModel.id === model.id) {
        setClipState((state) => ({
          ...state,
          editingModel: { ...editingModel, tags: editingModel.tags.filter((t) => t !== tag) },
        }));
      }

      await updateClipMutation.mutateAsync({ ...model, tags: model.tags.filter((t) => t !== tag) });

      enqueueSnackbar(`${tag} tag removed from ${model.note}.`, {
        action: (id) => (
          <IconButton size="small" onClick={() => closeSnackbar(id)}>
            <CloseIcon sx={{ color: 'black' }} />
          </IconButton>
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar, getState, updateClipMutation],
  );

  const handleSetClipRecordRef = React.useCallback(
    (clipId: number, ref: React.MutableRefObject<HTMLElement>) => {
      clipRecordRefsLookup.current = { ...clipRecordRefsLookup.current, [clipId]: ref };
    },
    [],
  );

  return {
    state: totalClipState,
    getState,
    handleAddBookmark,
    handleAddClip,
    handleAddTag,
    handleCancelEditing,
    handleConvertToClip,
    handleDelete,
    handleRemoveTag,
    handleSetBookmarkTime,
    handleSetClipRecordRef,
    handleSetEndTime,
    handleSetEndTimeOnSlider,
    handleSetStartTimeOnSlider,
    handleSetStartTime,
    handleStartEditing,
    handleSubmit,
    handleView,
    setFilter,
    setTagsFilter,
  };
};

export type ClipStateAndFunctions = ReturnType<typeof useClipState>;

export default useClipState;
