import { addSeconds, differenceInMilliseconds, differenceInSeconds } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Slider, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import { green, orange } from '@mui/material/colors';
import { Instance } from '@popperjs/core';

import useClipStateContext from '@/shared/hooks/useClipStateContext';
import { useTimestampState } from '@/shared/hooks/useTimestamp';
import { useTimestampEndState } from '@/shared/hooks/useTimestampEnd';
import { useTimestampStartState } from '@/shared/hooks/useTimestampStart';
import displayWallClock from '@/shared/services/displayWallClock';

import useCurrentGameClipsQuery from '../../clips/hooks/useCurrentGameClipsQuery';
import getClipsFilter from '../../clips/services/getClipsFilter';
import { ClipType } from '../../clips/types';
import { BootstrapTooltip } from './BootstrapTooltip';

type Props = { showingBookmarks: boolean };

const PLAYER_Z_INDEX = 100;

const MARK_WIDTH = 10;
const MARK_HEIGHT = 20;

const MainSlider = styled(Slider)({
  '& .MuiSlider-thumb': {
    zIndex: PLAYER_Z_INDEX + 1,
  },
});

const ClipSlider = styled(Slider)({
  '& .MuiSlider-thumb': {
    //    display: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0,
  },
  '& .MuiSlider-track': {
    zIndex: PLAYER_Z_INDEX,
  },
});

const PlaybackPositionSlider = ({ showingBookmarks }: Props) => {
  const [timestamp, setTimestamp] = useTimestampState();
  const [endTime] = useTimestampEndState();
  const [startTime] = useTimestampStartState();

  const sliderRef = useRef<HTMLInputElement>();
  const clipSliderRef = useRef<HTMLInputElement>();
  const popperRef = useRef<Instance>(null);

  const playbackPositionSeconds = Math.round(differenceInMilliseconds(timestamp, startTime) / 1000);
  const endPositionSeconds = Math.round(differenceInMilliseconds(endTime, startTime) / 1000);

  const {
    state: { isEditingClip, editingModel, tagsFilter, filter },
    handleView,
    handleSetEndTimeOnSlider,
    handleSetStartTimeOnSlider,
  } = useClipStateContext();

  const clipStartValueSeconds = useMemo(
    () => (isEditingClip ? differenceInSeconds(editingModel.startTimestamp, startTime) : 0),
    [isEditingClip, editingModel?.startTimestamp, startTime],
  );
  const clipEndValueSeconds = useMemo(
    () => (isEditingClip ? differenceInSeconds(editingModel.endTimestamp, startTime) : 0),
    [isEditingClip, editingModel?.endTimestamp, startTime],
  );

  const isWithinClip = useMemo(
    () =>
      isEditingClip &&
      playbackPositionSeconds >= clipStartValueSeconds &&
      playbackPositionSeconds <= clipEndValueSeconds,
    [playbackPositionSeconds, isEditingClip, clipStartValueSeconds, clipEndValueSeconds],
  );

  const timeLabel = (seconds: number) => {
    if(Number.isNaN(seconds)) return '00:00:00';
    return displayWallClock(addSeconds(startTime, seconds))
  };

  const currentGameClipsQuery = useCurrentGameClipsQuery();
  const marks = useMemo(
    () =>
      ((!isEditingClip && currentGameClipsQuery.data) || [])
        .filter(getClipsFilter(filter, tagsFilter))
        .map((c) => ({
          id: c.id,
          label: c.note,
          value:
            editingModel?.id === c.id
              ? playbackPositionSeconds
              : differenceInSeconds(
                  c.type === ClipType.Bookmark ? c.bookmarkTimestamp : c.startTimestamp,
                  startTime,
                ),
          editing: editingModel?.id === c.id,
          type: c.type,
          model: c,
        })),
    [
      currentGameClipsQuery.data,
      editingModel?.id,
      filter,
      isEditingClip,
      playbackPositionSeconds,
      startTime,
      tagsFilter,
    ],
  );

  const handleScrub = useCallback(
    (_e: Event, newPlaybackPosSeconds: number | number[]) => {
      if (Array.isArray(newPlaybackPosSeconds)) throw new Error('Not Implemented');
      if (newPlaybackPosSeconds < endPositionSeconds) {
        setTimestamp(addSeconds(startTime, newPlaybackPosSeconds)); // debounce
      }
    },
    [endPositionSeconds, setTimestamp, startTime],
  );

  const theme = useTheme();

  const getToolTipTitle = useCallback((mark) => {
    if (mark.type === ClipType.Bookmark) {
      return `Bookmark: ${mark.label}`;
    }
    if (mark.type === ClipType.Clip) {
      return `Clip: ${mark.label}`;
    }
    return '';
  }, []);

  const [mouseisHover, setMouseisHover] = useState(false);

  const [progressBarSize, setProgressBarSize] = React.useState<'small' | 'medium'>('small');

  const sliderStyle = {
    borderRadius: 0,
    color: '#ff0000',
    paddingBottom: 0,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    height: 4,
    mb: 0,
    '&:hover': {
      cursor: 'pointer',
      '& .MuiSlider-thumb': {
        width: 8,
        height: 8,
      },
    },
    '& .MuiSlider-thumb': {
      width: 0,
      height: 0,
      '&:hover': {
        width: 15,
        height: 15
      },
      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
      '&:before': {
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
      },
      '&:hover, &.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 8px ${
          theme.palette.mode === 'dark' ? 'rgb(255 255 255 / 2%)' : 'rgb(0 0 0 / 2%)'
        }`,
      },
      '&.Mui-active': {
        width: 20,
        height: 20,
      },
    },
    '& .MuiSlider-rail': {
      opacity: 0.28,
    },
  };

  const handleClipEditingChange = (e, v, thumb) => {
    const seconds = e.target.value[thumb];
    if (thumb === 0) {
      handleSetStartTimeOnSlider(addSeconds(startTime, seconds));
    }
    if (thumb === 1) {
      handleSetEndTimeOnSlider(addSeconds(startTime, seconds));
    }
    handleScrub(e, seconds);
  };

  const [tooltipTimePosition, setTooltipTimePosition] = useState<string>('');

  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const activeSliderRef = isEditingClip ? clipSliderRef : sliderRef;

  const handleMouseMoveOnProgressBar = (e: { clientX: any }) => {
    const { clientX } = e;
    const brect = activeSliderRef.current?.getBoundingClientRect();

    if (brect) {
      const mousePosition = clientX - brect?.left;
      const percentage = (mousePosition < 0 ? 0 : mousePosition) / brect.width;
      const timePosition = endPositionSeconds * percentage;

      setTooltipTimePosition(timeLabel(timePosition));

      positionRef.current = { x: e.clientX, y: brect.y };

      if (popperRef.current != null) {
        popperRef.current.update();
      }
    }
  };

  return (
    <Box
      mt={`${MARK_HEIGHT}px`}
      mx={1}
      alignSelf="stretch"
      position="relative"
      display="flex"
      onMouseEnter={() => setProgressBarSize('medium')}
      onMouseLeave={() => setProgressBarSize('small')}
    >
      {!isEditingClip && (
        <BootstrapTooltip
          title={tooltipTimePosition}
          key="timestamp"
          placement="top"
          PopperProps={{
            popperRef,
            anchorEl: {
              getBoundingClientRect: () =>
                new DOMRect(
                  positionRef.current.x,
                  sliderRef.current?.getBoundingClientRect().y,
                  0,
                  0,
                ),
            },
          }}
        >
          <MainSlider
            aria-label="mainSlider"
            getAriaValueText={(val) => `${val}s`}
            max={endPositionSeconds}
            onChange={handleScrub}
            ref={sliderRef}
            size={progressBarSize}
            step={1}
            sx={() => sliderStyle}
            value={playbackPositionSeconds}
            valueLabelDisplay="off"
            valueLabelFormat={timeLabel}
            onChangeCommitted={() => {
              sliderRef.current.querySelector('input').blur();
            }}

            onMouseMove={handleMouseMoveOnProgressBar}
          />
        </BootstrapTooltip>
      )}

      {isEditingClip && (
        <BootstrapTooltip
          title={tooltipTimePosition}
          key="timestamp"
          placement="top"
          PopperProps={{
            popperRef,
            anchorEl: {
              getBoundingClientRect: () =>
                new DOMRect(
                  positionRef.current.x,
                  clipSliderRef.current?.getBoundingClientRect().y,
                  0,
                  0,
                ),
            },
          }}
        >
          <ClipSlider
            aria-label="clipSlider"
            ref={clipSliderRef}
            getAriaValueText={(val) => `${val}s`}
            max={endPositionSeconds}
            onChange={handleClipEditingChange}
            size="small"
            step={1}
            sx={() => ({ ...sliderStyle, color: theme.palette.primary.main })}
            value={[clipStartValueSeconds, clipEndValueSeconds]}
            valueLabelDisplay={mouseisHover ? 'off' : 'on'}
            valueLabelFormat={timeLabel}
            onMouseEnter={() => setMouseisHover(true)}
            onMouseLeave={() => setMouseisHover(false)}
            onMouseMove={handleMouseMoveOnProgressBar}
          />
        </BootstrapTooltip>
      )}

      {showingBookmarks && (
        <Box
          sx={{
            position: 'absolute',
            top: `${-(MARK_HEIGHT / 2)}px`,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {marks.map((mark) => (
            <Tooltip title={getToolTipTitle(mark)} key={`mark-${mark.id}`}>
              <Box
                key={mark.value}
                aria-hidden="true"
                onClick={() => handleView(mark.model)}
                style={{
                  transition: 'all 0.2s ease-in-out',
                  position: 'absolute',
                  cursor: 'pointer',
                  width: 0,
                  height: 0,
                  borderRight: `${MARK_WIDTH / 2}px solid transparent`,
                  borderLeft: `${MARK_WIDTH / 2}px solid transparent`,
                  borderTop: `${MARK_HEIGHT / 1.5}px solid ${
                    mark.type === ClipType.Bookmark ? orange[500] : green[500]
                  }`,
                  left: mark.value
                    ? `calc(${(mark.value / endPositionSeconds) * 100}% - ${MARK_WIDTH / 2}px)`
                    : 0,
                  marginBottom: mark.editing ? 5 : undefined,
                  pointerEvents: 'all',
                }}
                sx={{
                  opacity: mark.editing ? 1 : 0.4,
                  '&:hover': {
                    transform: 'scale(1.5)',
                    opacity: 1,
                    cursor: 'pointer',
                  },
                }}
              />
            </Tooltip>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PlaybackPositionSlider;
