/* eslint-disable no-useless-escape */
import mixpanel from 'mixpanel-browser';

import { enableMixpanel, mixpanelApiKey, nodeEnv } from '@/shared/constants';

import { Tracker } from './Tracker';


function capitalizeFirstLetter(str: string): string {
  if (!str) return str;
  const words = str.split(' ');

  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1);
    return firstLetter + restOfWord;
  });

  return capitalizedWords.join(' ');
}

export class MixPanel implements Tracker {
  private static instance: MixPanel;

  private ready = false;

  private identified = false;

  private name = 'MixPanel';

  private constructor() {

    if (!enableMixpanel) {
      console.debug('Skipped MixPanel analytics');
      return;
    }
    if (!mixpanelApiKey) {
      console.debug('Skipped MixPanel analytics');
      return;
    }

    const debug = nodeEnv.startsWith('dev');
    try {
      mixpanel.init(mixpanelApiKey, {
        debug,
        loaded: () => {
          // @ts-ignore
          mixpanel.opt_in_tracking(true);
          const distinctId = mixpanel.get_distinct_id();
          this.ready = true;
          if (this.ready) {
            console.debug(
              `Loaded mixpanel analytics with ${mixpanelApiKey} and distinc id ${distinctId}`,
            );
          }
        },
      });
    } catch (e) {
      console.error(e);
      console.error('Skipped mixpanel analytics, due to previous error');
    }
  }

  navigation() {
    throw new Error(`navigation not implemented in ${this.name}`)
  }

  signIn(): void {
    throw new Error(`Sign In not implemented in ${this.name}`);
  }

  signOut(): void {
    throw new Error(`Sign Out not implemented in ${this.name}`)
  }


  public toString() {
    return this.name;
  }

  public static getInstance(): MixPanel {
    if (!MixPanel.instance) {
      MixPanel.instance = new MixPanel();
    }

    return MixPanel.instance;
  }

  public identify(userId: string, userProps: any) {
    try {
      if (!this.ready) return;
      if (this.identified) return;
      this.identified = true;
      mixpanel.identify(userProps.email);
      mixpanel.add_group('cognito-group', userProps?.group);
      mixpanel.set_group('department', userProps?.group);
      mixpanel.set_group('env', nodeEnv);
      
      mixpanel.people.set({
        "\$email": userProps.email,
        "\$distinct_id": userProps.email,
        "\$name": capitalizeFirstLetter(userProps.email.split('@')[0].replace('.', ' ')),
        "\$last_login": new Date()
      });
    } catch (error) {
      console.error(error);
    }
  }

  public track(event: string, payload?: any) {
    if (!this.ready) return;
    mixpanel.track(event, payload);
  }
}
