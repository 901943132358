import { useRef } from 'react';

import { TimestampMessage } from '../types';
import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from './withStateFromRef';
import { Tracking } from '../services/tracking';

export const useTimestampRef: WebSocketRefHook<Date> = (options = {}) => {
  const ref = useRef<Date>(new Date());
  const activityRef = useRef<number>(0);

  const handleChange = (value: Date) => {
    ref.current = value;

    if (options.onChange) {
      options.onChange(value);
    }
  };

  const handleActivePlaybackUpdate = (timestamp: number) => {
    if (timestamp !== activityRef.current) {
      activityRef.current = timestamp;
      Tracking.getInstance().track('Playback Progress', {
        category: 'Playback',
        unit: 'minutes',
        type: 'playback',
        minutes: timestamp,
      });
    }
  };

  const socket = useWebSocket<TimestampMessage>('/timestamp', {
    onMessage: (message) => {
      const date = new Date(Math.round(message.timestamp * 1000));
      handleActivePlaybackUpdate(Math.round(message.timestamp / 60));
      handleChange(date);
    },
  });

  const setTimestamp = (timestamp: Date) => {
    socket.send({
      timestamp: timestamp.getTime() / 1000,
    });

    handleChange(timestamp);
  };

  return [ref, setTimestamp];
};

export const useTimestampState = withStateFromRef(useTimestampRef);
