import React, { ReactNode, useEffect, useState } from 'react';
import { del } from '@truefit/http-utils';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import {
  ListItem,
  Divider,
  Popover,
  Stack,
  Tooltip,
  useTheme,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Avatar,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SettingsIcon from '@mui/icons-material/Settings';

import LogoutIcon from '@mui/icons-material/Logout';
import { Tracking } from '@/shared/services/tracking';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { ColorModeContext } from '@/features/scaffolding/components/App';
import useCognito from '@/features/authentication/hooks/useCognito';

type Props = {
  hasPageMenu: boolean;
  navigationOpen: boolean;
  pageMenuOpen: boolean;
  pageMenuWidth: number;
  setNavigationOpen: (open: boolean) => unknown;
  setPageMenuOpen: (open: boolean) => unknown;
  title: string;
  renderSummaryData?: () => ReactNode;
};

const getFirstLettersOfName = (email: string) => {
  if (!email) return null;
  const name = email.split('@')[0].replace('.', ' ');
  if (name.split(' ').length === 1) {
    return name.substring(0, 2).toUpperCase();
  }
  const [first, last] = name.split(' ');
  return `${first.substring(0, 1).toUpperCase()}${last.substring(0, 1).toUpperCase()}`;
};

// see https://mui.com/material-ui/react-app-bar/#fixed-placement
export const AppBarSpacer = () => (
  <Box
    sx={(theme) => ({
      ...theme.mixins.toolbar,
    })}
  />
);

function getRandomPastelColor(char1: string, char2: string): string {
  if (!char1 || !char2) return '#333';
  const seed = char1.charCodeAt(0) + char2.charCodeAt(0);
  const randomHue = (seed % 360) + 30; // Offset to avoid very similar colors
  const randomLightness = 70 + (seed % 5) * 5; // Vary lightness slightly

  return `hsl(${randomHue}, 100%, ${randomLightness}%)`;
}

const AppBar = ({
  hasPageMenu,
  navigationOpen,
  pageMenuOpen,
  pageMenuWidth,
  setNavigationOpen,
  setPageMenuOpen,
  title,
  renderSummaryData,
}: Props) => {
  // feature flags
  const { darkLightThemeSwitching } = useFlags();

  // context
  const colorMode = React.useContext(ColorModeContext);
  const { setOpen: setModalOpen } = React.useContext(UserStateContext);

  // custom hooks
  const theme = useTheme();
  const cognito = useCognito();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // react hooks
  const [email, setEmail] = useState<string>(null);
  const [avatarBgColor, setAvatarBgColor] = useState<string>('#333');

  useEffect(() => {
    setEmail(cognito.userAttributes?.email);
  }, [cognito.authenticated]);

  useEffect(() => {
    setAvatarBgColor(getRandomPastelColor(email?.charAt(0), email?.charAt(1)));
  }, [email]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // event handlers

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigationOpen = () => {
    setNavigationOpen(true);
  };

  const handleViewingToolsOpen = () => {
    setPageMenuOpen(true);
  };

  const handleSignOut = () => {
    cognito.signOut({
      async onSuccess() {
        Tracking.getInstance().signOut();
        history.push('/sign-in');
        await del('session');
      },

      onFailure() {
        enqueueSnackbar('An error occurred while signing out', { variant: 'error' });
      },
    });
  };

  return (
    <MuiAppBar
      position="fixed"
      sx={(sxTheme) => ({
        overflow: 'hidden',
        transition: sxTheme.transitions.create(['margin', 'width'], {
          easing: sxTheme.transitions.easing.sharp,
          duration: sxTheme.transitions.duration.leavingScreen,
        }),
        ...(pageMenuOpen && {
          width: `calc(100% - ${pageMenuWidth}px)`,
          marginRight: `${pageMenuWidth}px`,
          transition: sxTheme.transitions.create(['margin', 'width'], {
            easing: sxTheme.transitions.easing.easeOut,
            duration: sxTheme.transitions.duration.enteringScreen,
          }),
        }),
      })}
    >
      <Toolbar>
        {!navigationOpen && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleNavigationOpen}
          >
            <MenuIcon />
          </IconButton>
        )}
        {!renderSummaryData && (
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {title}
          </Typography>
        )}

        {!!renderSummaryData && renderSummaryData()}

        {darkLightThemeSwitching && (
          <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        )}

        <IconButton sx={{ ml: 1 }} color="primary" aria-label="User Menu" onClick={handleClick}>
          <Avatar />
        </IconButton>

        {hasPageMenu && !pageMenuOpen && (
          <Tooltip title="Viewing Tools" arrow>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              sx={{ ml: 3 }}
              onClick={handleViewingToolsOpen}
            >
              <MenuOpenIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack
          direction="column-reverse"
          justifyContent="center"
          alignItems="stretch"
          spacing={0.5}
        >
          <List sx={{ paddingTop: 0, marginBottom: 4 }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Avatar sx={{ bgcolor: avatarBgColor }}>{getFirstLettersOfName(email)}</Avatar>
                </ListItemIcon>
                <ListItemText secondary={email} />
              </ListItemButton>
            </ListItem>
            <Divider />

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setModalOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings & Preferences" />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton onClick={handleSignOut}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Popover>
    </MuiAppBar>
  );
};

AppBar.defaultProps = {
  renderSummaryData: null,
};

export default AppBar;
