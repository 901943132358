import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import TimeZoneSelector from '../../features/games/components/TimeZoneSelector';
import { ChangePasswordForm } from '@/features/authentication/components';

export interface UserSettingsDialogProps {
  id: string;
  keepMounted: boolean;
  open: boolean;
  onClose: (value?: string) => void;
}

export function UserSettingsDialog(props: UserSettingsDialogProps) {
  const { onClose, open, ...other } = props;
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(propsTabPanel: TabPanelProps) {
    const { children, value, index, ...rest } = propsTabPanel;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...rest}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      {...other}
      sx={{
        backdropFilter: 'blur(5px)',
      }}
    >
      <DialogTitle>Account</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ flexGrow: 1, display: 'flex', minHeight: 350 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={handleTabClick}
            aria-label="Settings"
            sx={{ borderRight: 1, borderColor: 'divider', minWidth: 150 }}
          >
            <Tab label="Preferences" {...a11yProps(1)} />
            <Tab label="Security" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <TimeZoneSelector />
          </TabPanel>
          <TabPanel style={{ width: '100%' }} value={selectedTab} index={1}>
            <ChangePasswordForm />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
