import { useRef } from 'react';

import { TimestampMessage } from '../types';
import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from './withStateFromRef';

export const useTimestampEndRef: WebSocketRefHook<Date> = (options = {}) => {
  const ref = useRef<Date>(new Date());

  const handleChange = (value: Date) => {
    ref.current = value;

    if (options.onChange) {
      options.onChange(value);
    }
  };

  useWebSocket<TimestampMessage>('/timestamp/end', {
    onMessage: (message) => {
      if (message.timestamp <= 0) return;
      const date = new Date(Math.round(message.timestamp * 1000));
      handleChange(date);
    },
  });

  return [ref];
};

export const useTimestampEndState = withStateFromRef(useTimestampEndRef);
