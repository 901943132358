import React from 'react';
import TimezoneSelect, { allTimezones } from '@mahhoura/react-timezone-select-mui';
import { UserStateContext } from '@/shared/components/UserStateProvider';

export default function TimeZoneSelector() {
  const { isLoading, user, updateUserTimezone } = React.useContext(UserStateContext);
  const handleChange = (changedTZ) => {
    updateUserTimezone(changedTZ.value);
  };
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  if (user.tz === undefined) {
    return <></>;
  }
  return (
    <TimezoneSelect
      disabled={isLoading}
      onChange={handleChange}
      timezones={allTimezones}
      label="Timezone"
      variant="filled"
      helperText={`Your current timezone is ${timeZone}`}
      value={user.tz}
    />
  );
}
